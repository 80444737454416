import React from 'react';
import classes from './Menu.module.scss';
import { Link } from "react-router-dom";

const Menu = ({ status }) => {
    return (
        <div className={[classes.Menu, status && classes.MenuActive].join(' ')}>
            <ul>
                <li><Link to="/stations" className={classes.Link}>СПИСОК СТАНЦИЙ</Link></li>
                <li><Link to="/instructions" className={classes.Link}>ИНСТРУКЦИИ</Link></li>
                <li><Link to="/plans" className={classes.Link}>ТАРИФЫ</Link></li>
                <li><Link strict to="/cabinet#rinex" className={classes.Link}>RINEX</Link></li>
                <li><Link to="/rtx" className={classes.Link}>RTX</Link></li>
            </ul>
        </div>
    )
}

export default Menu;
