import React from 'react';
import styles from "./SubscribeProgress.module.scss";
import {useSelector} from "react-redux";

const progress = [
    {
        label: 'Информация о подписке',
        value: 'Create',
    },
    {
        label: 'Оплата заказа',
        value: 'Card'
    },
    {
        label: 'Банковские реквизиты',
        value: 'Bank'
    }
];

const SubscribeProgress = () => {
    const { payMethod, step } = useSelector(state => state.subscribe);

    if (step === "Finish") return null;

    return (
        <div className={styles.progress}>
            { progress.filter((item) => (item.value === payMethod || item.value === 'Create')).map((item, index) => {
                return (
                    <div className={[styles.progress__item, item.value === step && styles.progress__item_active].join(' ')} key={index}>
                        <h2>{index + 1} шаг</h2>
                        <p>
                            {item.label}
                        </p>
                        <span />
                    </div>
                )
            })}
        </div>
    );
};

export default SubscribeProgress;
