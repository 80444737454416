import React, {useState} from 'react';
import {useMutation} from "@apollo/client";
import Form from "../../../Form/Form";
import ChangeSchema from "./ChangeForm.schema";
import {CHANGE_PASSWORD } from "../../../../graphql";
import Button from "../../../../ui/Button/Button";
import TextField from "../../../../ui/TextField/TextField";
import Wrapper from "../../../Wrapper/Wrapper";
import {useLocation} from "react-router-dom";

const ChangeForm = ({ stepHandler }) => {
    const [errors, setErrors] = useState();

    const location = useLocation();
    const hash = new URLSearchParams(location.search).get("hash")


    const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
        update(_, { data } ) {
            stepHandler("Success")
        },
        onError(err) {
            console.log(err)
        }
    });

    const onSubmit = async (data) =>
        await changePassword({ variables: { input: { ...data, hash } }})

    return (
        <Wrapper
            title="Восстановление пароля"
            subscription={"Введите данные указанные при регистрации \nи следуйте дальнейшим инструкциям"}
        >
            <Form
                onSubmit={onSubmit}
                validationSchema={ChangeSchema}
            >
                {({ control }) => (
                    <>
                        <TextField
                            type="password"
                            name="password"
                            label="Новый пароль"
                            control={control}
                            placeholder="Введите новый пароль"
                        />
                        <TextField
                            type="password"
                            name="confirmPassword"
                            label="Подтвердите новый пароль"
                            control={control}
                            placeholder="Повторите новый пароль"
                        />
                        <Button type="submit" onClick={onSubmit} disabled={loading}>Изменить пароль</Button>
                    </>
                )}
            </Form>
        </Wrapper>
    );
};

export default ChangeForm;
