import React from 'react';
import {useDispatch} from "react-redux";
import {useMutation, useQuery} from "@apollo/client";
import Button from "../../../../ui/Button/Button";
import styles from "./RequisiteList.module.scss";
import checkbox from "../../../../assets/img/check.svg";
import {changeSubscribeStep} from "../../../../store/reducers/SubscribeSlice";
import {CHANGE_DEFAULT_REQUISITE, GET_REQUISITE} from "../../../../graphql";


const RequisiteList = ({ createHandler }) => {
    const dispatch = useDispatch();

    const { data: { getUserRequisite: requisites } = {}, loading } = useQuery(GET_REQUISITE, {
        fetchPolicy: "cache-and-network"
    });

    const [changeDefaultRequisiteHandler] = useMutation(CHANGE_DEFAULT_REQUISITE, {
        refetchQueries: () => [{
            query: GET_REQUISITE
        }],
    });

    const changeHandler = async (_id) =>
        await changeDefaultRequisiteHandler({ variables: { input: { _id } } })


    return (
        <div className={styles.requisite}>
            <div className={styles.requisite__title}>
                <h1>Для продолжения выберите реквизиты из списка</h1>
                <h3>Вы всегда можете добавить новые реквизиты</h3>
            </div>
            <div className={styles.requisite__items}>
                { requisites && requisites.map((item, index) => (
                    <div onClick={() => changeHandler(item._id)} className={[styles.requisite__item, item.default ? styles.requisite__item_active : ""].join(' ')} key={item._id}>
                        {item.default && <div className={styles.requisite__checked}><img src={checkbox} alt=""/></div>}
                        <h2>{item.companyName} ({item.companyBin})</h2>

                        <h4>{item.companyAddress}, {item.bankName}, {item.bankRequisites}</h4>
                    </div>
                ))}
                <Button theme="second" className={styles.requisite__add} onClick={() => createHandler(true)}>Добавить новые реквизиты</Button>
            </div>
            <Button onClick={() => dispatch(changeSubscribeStep({ step: "Finish" }))}>Продолжить</Button>
        </div>
    );
};

export default RequisiteList;
