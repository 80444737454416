import React from 'react';
import Wrapper from "../../Wrapper/Wrapper";
import AuthWithPhone from "../auth/AuthWithPhone/AuthWithPhone";

const RegisterContainer = () => {
    return (
        <Wrapper
            title="Регистрация"
            subscription="Для регистрации введите данные:"
        >
            <AuthWithPhone />
        </Wrapper>
    );
};

export default RegisterContainer;
