import React from 'react';
import Form from "../../../Form/Form";
import {useMutation} from "@apollo/client";
import Button from "../../../../ui/Button/Button";
import TextField from "../../../../ui/TextField/TextField";
import RequisiteCreateSchema from "./RequisiteCreate.schema";
import {CREATE_REQUISITE, GET_REQUISITE} from "../../../../graphql";
import styles from "../../subscribe/SubscribeBank/SubscribeBank.module.scss";


const RequisiteCreate = ({ createHandler }) => {
    const [createRequisite, { loading }] = useMutation(CREATE_REQUISITE, {
        refetchQueries: () => [{
            query: GET_REQUISITE
        }],
        onCompleted: () => createHandler(false)
    });

    const onSubmit = async data =>
        await createRequisite({ variables: { input: { ...data }}})

    return (
        <div className={styles.bank}>
            <div className={styles.bank__title}>
                <h1>Создание банковских реквизитов</h1>
                <h3>Для продолжения необходимо заполнить все поля</h3>
            </div>
            <Form
                onSubmit={onSubmit}
                validationSchema={RequisiteCreateSchema}
            >
                {({ control }) => (
                    <>
                        <TextField
                            label="БИН"
                            name="companyBin"
                            control={control}
                            placeholder="Укажите БИН"
                        />
                        <TextField
                            control={control}
                            name="companyName"
                            label="Наименование компании"
                            placeholder="Введите наименование компании"
                        />
                        <TextField
                            control={control}
                            name="companyAddress"
                            label="Юридический адрес"
                            placeholder="Укажите юридический адрес"
                        />

                        <TextField
                            name="bankName"
                            control={control}
                            label="Наименование банка"
                            placeholder="Введите наименование банка"
                        />
                        <TextField
                            control={control}
                            name="bankRequisites"
                            label="Расчетный счет компании"
                            placeholder="Расчетный счет компании"
                        />
                        <Button type="submit" disabled={loading} tag="button">Продолжить</Button>
                    </>
                )}
            </Form>
        </div>
    );
};

export default RequisiteCreate;
