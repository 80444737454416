import {Navigate, Outlet} from "react-router-dom";
import {FETCH_USER} from "../../graphql";
import {useQuery} from "@apollo/client";
import Loading from "../../components/Loading/Loading";


const ProtectedRoute = () => {
  console.log('PROTECTED')
  const { data, loading } = useQuery(FETCH_USER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      localStorage.setItem("accessToken", data?.userAuth?.accessToken);
    },
    onError: () => {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
    }
  })

  if (!loading && !data?.userAuth) {
    return <Navigate to="/account" />;
  }

  if (!loading && data?.userAuth) {
    return <Outlet context={{ user: data?.userAuth }} />;
  }

  return <Loading />;
}



export default ProtectedRoute;
