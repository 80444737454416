import React, {useState} from 'react';
import { useQuery } from "@apollo/client";
import { GET_REQUISITE } from "../../../../graphql";
import RequisiteCreate from "../../requisite/RequisiteCreate/RequisiteCreate";
import RequisiteList from "../../requisite/RequisiteList/RequisiteList";


const SubscribeBank = () => {
    const [show, setShow] = useState(false);

    const { data: { getUserRequisite: requisites } = {}, loading } = useQuery(GET_REQUISITE, {
        fetchPolicy: "cache-and-network"
    });


    if (loading) <div>загрузка</div>;

    if (show || (requisites && requisites.length <= 0)) {
        return <RequisiteCreate createHandler={setShow} />;
    }

    return <RequisiteList requisites={requisites} createHandler={setShow} />
};

export default SubscribeBank;
