import {clsx} from "clsx";
import styles from "./Modal.module.scss";
import { CSSTransition } from "react-transition-group";
import React, { useEffect, useRef, useState } from 'react';

const overlayAnimation = {
  enter: styles.modal__overlay__enter,
  enterActive: styles.modal__overlay__enterActive,
  exit: styles.modal__overlay__exit,
  exitActive: styles.modal__overlay__exitActive,
};

const contentAnimation = {
  enter: styles.modal__container__enter,
  enterActive: styles.modal__container__enterActive,
  exit: styles.modal__container__exit,
  exitActive: styles.modal__container__exitActive,
};


const ModalOverlay = ({ onClose, opened, children, size }) => {
  const overlayRef = useRef(null);
  const contentRef = useRef(null);

  const [animationIn, setAnimationIn] = useState(false);

  const modalClassnames = clsx(styles.modal__container, {
    [styles.modal__container__size_default]: size === "default",
    [styles.modal__container__size_small]: size === "small",
  });

  useEffect(() => {
      setAnimationIn(opened);
  }, [opened]);

  return (
    <div className={styles.modal} role="dialog">
    <CSSTransition
        mountOnEnter
        unmountOnExit
        timeout={300}
        in={animationIn}
        nodeRef={overlayRef}
        classNames={overlayAnimation}
      >
        <div
          tabIndex={0}
          role="button"
          ref={overlayRef}
          onClick={onClose}
          className={styles.modal__overlay}
        />
      </CSSTransition>
      <CSSTransition
        mountOnEnter
        unmountOnExit
        timeout={300}
        in={animationIn}
        nodeRef={contentRef}
        classNames={contentAnimation}
      >
        <div className={modalClassnames} ref={contentRef}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};

export default ModalOverlay;
