import React from 'react';
import classes from "../Rtx.module.scss";
import {motion} from "framer-motion";
import rangeSput from "../../../assets/img/rangepoint-rtx-sputnik.jpg";
import rangeMap from "../../../assets/img/centerpoint-rtx-1.png";

const RangePointRtx = () => {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ duration: 0.5 }}
            key="pay"
            className={classes.BlockTarifTabsItem}
        >
            <div className={classes.BlockTarifTabsTop}>
                <div>
                    <b>Доставка поправки</b>
                    <img src={rangeSput} alt=""/>
                </div>
                <div>
                    <b>Точность</b>
                    <p> &#60;6" (15 см)</p>
                </div>
                <div>
                    <b>Время инициализации</b>
                    <p>1 - 5 минут</p>
                </div>
            </div>
            <div className={classes.BlockTarifTabsImage}>
                <img src={rangeMap} alt=""/>
                <p>
                    Служба коррекции RangePoint RTX обеспечивает передачу дифференциальных GNSS поправок в большей части мира. RangePoint RTX работает с GNSS приемниками, которые имеют встроенный дисплей Trimble FMX, CFX-750 дисплей или GNSS приемник.
                </p>
            </div>
            <div className={classes.BlockTarifTabsInfo}>
                <ul>
                    <li>Высокая точность - RangePoint RTX обеспечивает точность 6" (15 см).</li>
                    <li>Повышенная доступность спутников - RangePoint RTX совместим с GNSS, что позволяет использовать
                        сигналы и GPS и ГЛОНАСС на существующих GNSS приемниках: с встроенным дисплеем FMX, дисплеем
                        CFX-750, и GNSS приемником AG-372.
                    </li>
                    <li>Бесплатная коррекция спутниковых данных ГЛОНАСС - с получением подписки CenterPoint RTX. Доступ
                        к коррекции ГЛОНАСС будет разблокирован бесплатно в течение всего срока подписки.
                    </li>
                    <li>Возможность продолжать работу во время потери коррекции сигнала в течении двух минут.</li>
                    <li>Быстрая инициализация - при использовании подписки RangePoint RTX заявленная точность может
                        быть достигнута в течение 5 минут или меньше.
                    </li>
                    <li>Отсутствие базовой станции - не нужно бояться потерять прием радио-сигнала, так как базовая
                        станция не нужна.
                    </li>
                    <li>Широкий доступ - передача дифференциальных поправок по спутниковым каналам доступна в большей
                        части мира.
                    </li>
                </ul>
            </div>
        </motion.div>
    )
}

export default RangePointRtx;
