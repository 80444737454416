import React, {useCallback, useState} from 'react';
import ResetForm from "../ResetForm/ResetForm";
import ResetSuccess from "../ResetSuccess/ResetSuccess";
import {AnimatePresence, motion} from "framer-motion";

const cardVariants = {
    initial: { opacity: 0, y: '25%' },
    animate: { opacity: 1, y: '0%' },
    exit: { opacity: 0, y: '-25%' }
};

const ResetContainer = () => {
    const [type, setType] = useState("Init");

    const content = [
        { key: "Init", component: (props) => <ResetForm {...props} /> },
        { key: "Success", component: (props) => <ResetSuccess {...props} /> },
    ];

    const stepHandler = useCallback(step => {
        setType(step);
    }, []);

    return (
        <AnimatePresence>
            { content.map(({ component, key }, index) => (
                <React.Fragment key={key}>
                    { key === type ? (
                        <motion.div
                            exit="exit"
                            key={key}
                            animate="animate"
                            initial="initial"
                            variants={cardVariants}
                            style={{ width: '100%'}}
                        >
                            { component({ stepHandler }) }
                        </motion.div>
                    ) : null }
                </React.Fragment>
            )) }
        </AnimatePresence>
    );
};

export default ResetContainer;
