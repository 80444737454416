import React from 'react';
import classes from "../Label/Label.module.scss";


const Label = ({ htmlFor, children }) => {
    return (
        <label htmlFor={htmlFor} className={classes.label}>{children}:</label>
    )
};

export default Label;
