import React from 'react';
import { useQuery } from "@apollo/client";
import { FETCH_STATIONS_OPTIONS } from "../../../graphql";
import SelectField from "../../../ui/SelectField/SelectField";
import Flex from "../../../ui/Flex/Flex";

const Stations = ({ control, name, label, stationCount }) => {
    const { loading, data: { getStations: stations } = {} } = useQuery(FETCH_STATIONS_OPTIONS);

    return (
        <Flex>
            { new Array(stationCount).fill(null).map((_, index) => {
                return (
                    <SelectField
                        control={control}
                        loading={loading}
                        name={`${name}.${index}`}
                        label={`${label} №${index + 1}`}
                        placeholder={`Укажите станцию`}
                        options={stations && stations.map(station => {
                            return {
                                value: station._id,
                                label: `${station.city} (${station.code})`
                            }
                        })}
                    />
                )
            }) }
        </Flex>
    )
};

export default Stations;
