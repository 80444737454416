import Error from "../Error/Error";
import Group from "../Group/Group";
import Label from "../Label/Label";
import React, { useState } from "react";
import styles from "./PayField.module.scss";
import { useController } from "react-hook-form";
import card from '../../assets/img/card.svg';
import invoice from '../../assets/img/invoice.svg';


const PayField = ({
   name,
   label,
   control,
   onChange,
   options
}) => {
    const { field, fieldState: { error } } = useController({ name, control });

    const [activeIndex, setActiveIndex] = useState(0);

    const changeHandler = (item, index) => {
        setActiveIndex(index);
        onChange(item);
        field.onChange(item.value);
    }

    return (
        <Group>
            { label && <Label>{label}</Label>}
            <div className={styles.pay__container}>
                <div className={styles.pay__group}>
                    { options.map((item, index) => (
                        <div
                            key={index}
                            className={[styles.pay__item, activeIndex === index && styles.pay__item_active].join(' ')}
                            onClick={() => changeHandler(item, index)}
                        >
                            <img src={item.value === 'Card' ? card : invoice} alt="" />
                            {item.name}
                        </div>
                    )) }
                </div>
            </div>
            { error && <Error message={error.message} /> }
        </Group>
    )
}

export default PayField;
