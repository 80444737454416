import React from 'react';
import Modal from "../../../../ui/Modal/Modal";
import StationForm from "../StationForm/StationForm";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import StationSuccess from "../StationSuccess/StationSuccess";
import { changeRequestStep, closeRequestModal } from "../../../../store/reducers/RequestSlice";


const cardVariants = {
    initial: { opacity: 0, y: '-25%' },
    animate: { opacity: 1, y: '0%' },
    exit: { opacity: 0, y: '25%' }
};

const cardVariantsFirst = {
    initial: { opacity: 1, y: '0%' },
    animate: { opacity: 1, y: '0%' },
    exit: { opacity: 0, y: '25%' }
};


const StationRequest = () => {
    const dispatch = useDispatch();

    const { opened, step } = useSelector(state => state.request);

    const stepHandler = (step) => dispatch(changeRequestStep({ step }));
    const closeHandler = () => dispatch(closeRequestModal());

    const content = [
        { key: "Create", component: (props) => <StationForm {...props} /> },
        { key: "Success", component: (props) => <StationSuccess {...props} /> },
    ];

    return (
        <Modal isOpened={opened} onClose={closeHandler}>
            {({ }) => (
                <AnimatePresence>
                    { content.map(({ component, key }, index) => (
                        <React.Fragment key={key}>
                            { key === step ? (
                                <motion.div
                                    exit="exit"
                                    key={key}
                                    animate="animate"
                                    initial="initial"
                                    style={{ width: '100%'}}
                                    variants={key === "Create" ? cardVariantsFirst : cardVariants}
                                >
                                    { component({
                                        closeHandler,
                                        stepHandler
                                    }) }
                                </motion.div>
                            ) : null }
                        </React.Fragment>
                    )) }
                </AnimatePresence>
            )}
        </Modal>
    );
};

export default StationRequest;
