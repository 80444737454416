import styles from "./Pin.module.scss";
import usePrevious from "../../hooks/usePrevious";
import React, {memo, useEffect, useRef} from "react";


const PinInput = (props) => {
    const { focus, autoFocus, pinLength, ...rest } = props;
    const inputRef = useRef(null);
    const prevFocus = usePrevious(!!focus);

    useEffect(() => {
        if (inputRef.current) {
            if (focus && autoFocus) {
                inputRef.current.focus();
            }
            if (focus && autoFocus && focus !== prevFocus) {
                inputRef.current.focus();
                inputRef.current.select();
            }
        }
    }, [autoFocus, focus, prevFocus]);

    return (
        <div className={styles.pin}>
            <input className={styles.pin__input} ref={inputRef} {...rest} type="number" />
        </div>
    )
}



const Pin = memo(PinInput);

export default Pin;
