import { clsx } from "clsx";
import styles from "./Select.module.scss";
import SelectOption from "./SelectOption";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import React, {useCallback, useEffect, useRef, useState} from 'react';

const Select = (props) => {
  const { name, onChange, placeholder, options = [], loading = false, defaultValue, isError, CreateComponent } = props;

  console.log(placeholder);

  const ref = useRef(null);
  const [selectWidth, setSelectWidth] = useState('100%');

  const [opened, setOpened] = useState(false);
  const [selectedOptions, setSelectedOption] = useState(() => {
    return options.filter(item => item.value === defaultValue)[0]
  });

  const closeHandler = () => setOpened(false);
  const openHandler = () => setOpened(opened => !opened);

  const selectHandler = useCallback((option) => {
    setSelectedOption(option);
    openHandler();
    onChange(option);
  }, [onChange]);

  const arrowStyle = clsx(styles.select__arrow, {
    [styles.select__arrow_closed]: !opened,
    [styles.select__arrow_opened]: opened
  });

  const selectStyle = clsx(styles.select__input, {
    [styles.select__input_state_error]: isError
  });

  const placeholderStyle = clsx(styles.select__placeholder, {
    [styles.select__placeholder_empty]: !selectedOptions,
    [styles.select__placeholder_selected]: selectedOptions
  });

  const dropdownStyle = clsx(styles.select__dropdown, {
    [styles.select__dropdown_opened]: opened
  });

  useOnClickOutside(ref, closeHandler);

  useEffect(() => {
    const select = ref.current;
    if (!select) return;

    const { width: selectWidth } = select.getBoundingClientRect();

    setSelectWidth(selectWidth - 50);
  }, [selectedOptions]);


  return (
    <div className={styles.select} ref={ref}>
        <div className={selectStyle} onClick={openHandler} id={name}>
          <span style={{ maxWidth: selectWidth }} className={placeholderStyle}>
            {!selectedOptions ? placeholder: selectedOptions.label }
          </span>
          { !loading && (
            <span className={arrowStyle}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </span>
          ) }
          {/*{ loading && (*/}
          {/*  <span className={styles.select__loading}><Icon name="loading" /></span>*/}
          {/*) }*/}
        </div>
        <div className={dropdownStyle}>
          <ul className={styles.options__items} role="listbox" tabIndex={-1}>
            { options.map(option => (
              <SelectOption
                option={option}
                key={option.value}
                selected={selectedOptions?.value === option.value}
                onChange={(value) => selectHandler(value)}
              />
            )) }
          </ul>
          <div className={styles.options__create}>
            { CreateComponent ? <CreateComponent /> : null }
          </div>
        </div>
    </div>
  );
};

export default Select;
