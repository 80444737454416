import React, { useState } from 'react';
import {useForm} from "react-hook-form";
import {useMutation} from "@apollo/client";
import {VERIFY_OTP_CODE} from "../../../../graphql";
import PinField from "../../../../ui/PinField/PinField";
import Button from "../../../../ui/Button/Button";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {closeAuthModal} from "../../../../store/reducers/AuthSlice";

const AuthOtpForm = ({ }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState();

    const { handleSubmit, setValue } = useForm({
        mode: "onSubmit"
    });

    const [verifyOtp, { loading }] = useMutation(VERIFY_OTP_CODE, {
        update(_, { data: { userVerifyOtp: userData } } ) {
            localStorage.setItem('accessToken', userData.accessToken);
            dispatch(closeAuthModal());
            navigate('/cabinet');
        },
        onError(err) {
            setErrors(err.graphQLErrors[0].extensions.exception.errors);
        }
    });

    const onSubmit = handleSubmit(async (data) => {
        console.log(data);
        await verifyOtp({ variables: { input: { ...data }}})
    });

    return (
        <form>
            <PinField
                autoFocus
                pinLength={5}
                disabled={loading}
                label="Введите смс-код"
                onChangeOTP={(pin) => setValue('otp', pin) }
            />
            <Button onClick={onSubmit} disabled={loading}>Продолжить</Button>
        </form>
    );
};

export default AuthOtpForm;
