import {combineReducers} from "redux";
import {configureStore} from "@reduxjs/toolkit";

import authReducer from "./reducers/AuthSlice";
import requestReducer from "./reducers/RequestSlice";
import subscribeReducer from "./reducers/SubscribeSlice";


const rootReducer = combineReducers({
    auth: authReducer,
    request: requestReducer,
    subscribe: subscribeReducer
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer
    })
}
