import styles from "./Link.module.scss";
import {useMatch, useResolvedPath, Link as RLink} from "react-router-dom";

const Link = ({ children, to, ...props }) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });

    return (
        <RLink
            to={to}
            className={[styles.link, match && styles.link_active].join(" ")}
            {...props}
        >
            {children}
        </RLink>
    );
}

export default Link;
