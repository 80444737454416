import React from "react";
import { clsx } from 'clsx';
import styles from "./Input.module.scss";
import MaskedInput from "react-text-mask";

const Input = (props) => {
    const { isError, mask = undefined, ...other } = props;

    const inputStyle = clsx(styles.input__container, {
        [styles.input__container_state_error]: isError
    });

    return (
        <div className={inputStyle}>
            { mask ? (
                <MaskedInput
                    mask={mask}
                    aria-invalid={isError}
                    keepCharPositions={true}
                    className={styles.input}
                    {...other}
                />
            ) : (
                <input
                    aria-invalid={isError}
                    className={styles.input}
                    {...other}
                />
            ) }
        </div>

    )
}

export default Input;
