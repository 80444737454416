import React from 'react';
import arrow from "../../../assets/img/arrow.svg";
import classes from './Tab.module.scss';
import { useQuery } from "@apollo/client";
import { FETCH_DEVICE } from "../../../graphql";
import { Subscriptions } from "../../Subsciptions/Subscriptions";
import {useDispatch} from "react-redux";
import {openSubscribeModal} from "../../../store/reducers/SubscribeSlice";


const Devices = () => {
    const dispatch = useDispatch();
    const { loading, data: { getUserSubscriptions: subscriptions } = {} } = useQuery(FETCH_DEVICE, {
        pollInterval: 2000,
    });

    const openCreateModalHandler = () => dispatch(openSubscribeModal());


    if (loading) {
        return (
            <div className={classes.Wrapper}>
                Загрузка
            </div>
        )
    }

    return (
        <>
            <div className={classes.Wrapper}>
                { subscriptions && subscriptions.length > 0 ? (
                    <>
                        <div className={classes.TableWrapper}>
                            <table className={classes.Table}>
                                <thead>
                                <tr>
                                    <td className={classes.Password} />
                                    <td>ID</td>
                                    <td>Приемник</td>
                                    <td>Тарифный план</td>
                                    <td>Дата активации</td>
                                    <td>Дата окончания</td>
                                    <td>Статус оплаты</td>
                                </tr>
                                </thead>
                                <tbody>
                                    <Subscriptions subscriptions={subscriptions} />
                                </tbody>
                            </table>
                        </div>
                        <button className={classes.ButtonAdd} onClick={openCreateModalHandler}>добавить приемник <img src={arrow} alt=""/></button>
                    </>
                ) : (
                    <div className={classes.Text}>
                        <p>Для получения доступа к RINEX, необходимо оформить хоть одну подписку.</p>
                        <button className={classes.ButtonAdd} onClick={openCreateModalHandler}>добавить приемник <img src={arrow} alt=""/></button>
                    </div>
                )}
            </div>
        </>
    )
};

export default Devices;
