import React, {useCallback, useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import AuthWithPhone from "../AuthWithPhone/AuthWithPhone";
import AuthWithEmail from "../AuthWithEmail/AuthWithEmail";
import AuthNavigator from "../AuthNavigator/AuthNavigator";
import Wrapper from "../../../Wrapper/Wrapper";

const cardVariants = {
    initial: { opacity: 0, x: '25%' },
    animate: { opacity: 1, x: '0%' },
    exit: { opacity: 0, x: '-25%' }
};

const AuthContainer = ({ typeHandler }) => {
    const [method, setMethod] = useState("Email");

    const methodHandler = useCallback(method => {
        setMethod(method);
    }, [])

    const content = [
        { key: "Email", title: "По email адресу", component: (props) => <AuthWithEmail {...props} /> },
        { key: "Phone", title: "По номеру телефона", component: (props) => <AuthWithPhone {...props} /> },
    ];

    return (
        <Wrapper
            title="Личный кабинет"
            subscription="Для продолжения необходимо авторизоваться, выберите удобный способ:"
        >
            <AuthNavigator
                method={method}
                navigator={content}
                methodHandler={methodHandler}
            />
            <AnimatePresence>
                { content.map(({ component, key }, index) => (
                    <React.Fragment key={key}>
                        { key === method ? (
                            <motion.div
                                exit="exit"
                                key={key}
                                animate="animate"
                                initial="initial"
                                variants={cardVariants}
                                style={{ width: '100%'}}
                            >
                                { component({
                                    typeHandler,
                                    methodHandler
                                }) }
                            </motion.div>
                        ) : null }
                    </React.Fragment>
                )) }
            </AnimatePresence>
        </Wrapper>
    )
};

export default AuthContainer;
