import React from 'react';
import Logout from "./../../Logout/Logout"
import classes from "./Button.module.scss";
import personalDark from "../../../assets/img/personal.svg";
import personalWhite from "../../../assets/img/personalwhite.svg";
import { Link } from "react-router-dom";
import useIsAuth from "../../../hooks/useIsAuth";


const ButtonAuth = (props) => {
    const { isAuth } = useIsAuth();

    if (isAuth) {
        return  (
            <Link className={[classes.AuthButton, props.color === 'white' ? classes.AuthButtonWhite : classes.AuthButtonDark].join(' ')} to="/cabinet">
                <img src={props.color === 'white' ? personalWhite : personalDark} alt=""/>
                <span>Личный кабинет</span>
                <Logout />
            </Link>
        )
    } else {
        return (
            <Link className={[classes.AuthButton, props.color === 'white' ? classes.AuthButtonWhite : classes.AuthButtonDark].join(' ')} to="/cabinet">
                <img src={props.color === 'white' ? personalWhite : personalDark} alt=""/>
                <span>Войти в кабинет</span>
            </Link>
        )
    }
}

export default ButtonAuth;
