import React from "react";
import classes from "../Error/Error.module.scss";

const Error = ({ message, style }) => {
    return (
        <span className={classes.error} style={style}>{message}</span>
    )
}

export default Error;
