import React, {useState, useEffect} from 'react';
import classes from './Tabs.module.scss';
import Devices from './Tab/Devices';
import Rinex from './Tab/Rinex';
import Personal from './Tab/Personal';
import { useLocation } from "react-router-dom";


const Tabs = () => {
    const [tab, setTab] = useState('device');

    const location = useLocation();
    const hashTab = location.hash.replace('#', '') || 'device';

    useEffect(() => {
        setTab(hashTab);
    }, [hashTab])


    const active = (name) => {
        if (tab === name) {
            return classes.Link + ' ' + classes.LinkActive;
        }  else {
            return classes.Link;
        }
    }
    return (
        <div className={classes.Tabs}>
            <div className={classes.Nav}>
                <button className={active('device')} onClick={() => setTab('device')}><span>Ваши подписки</span></button>
                <button className={active('rinex')} onClick={() => setTab('rinex')}><span>Скачать RINEX</span></button>
                {/*<button className={active('personal')} onClick={() => setTab('personal')}><span>Личные данные</span></button>*/}
            </div>
            <div className={classes.Wrapper}>
                { tab === 'device' && <Devices /> }
                { tab === 'rinex' && <Rinex /> }
                {/*{ tab === 'personal' && <Personal /> }*/}
            </div>
        </div>
    )
};

export default Tabs;



