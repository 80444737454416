import Error from "../../ui/Error/Error";
import React, {useEffect, useState} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

const Form = ({
    error, onSubmit, defaultValues, validationSchema, children
}) => {
    const methods = useForm({
        mode: "onSubmit",
        shouldFocusError: false,
        defaultValues: defaultValues,
        resolver: validationSchema ? yupResolver(validationSchema) : undefined
    });

    const [canFocus, setCanFocus] = useState(true)

    const onError = () => {
        setCanFocus(true)
    }

    useEffect(() => {
        if (methods.formState.errors && canFocus) {
            const elements = Object.keys(methods.formState.errors)
                .map((name) => document.getElementsByName(name)[0])
                .filter((el) => !!el);

            elements.sort((a, b) =>
                a.getBoundingClientRect().top - b.getBoundingClientRect().top);

            if (elements.length > 0) {
                let errorElement = elements[0];
                errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
                errorElement.focus({ preventScroll: true });
                setCanFocus(false);
            }
        }
    }, [methods.formState, canFocus]);


    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit, onError)} action="#">
                { error && <Error message={error} style={{ marginBottom: "1rem" }} /> }
                {children(methods)}
            </form>
        </FormProvider>
    )
}

export default Form;
