import React from 'react';
import classes from "../Rtx.module.scss";
import {motion} from "framer-motion";
import rtkSputnik from "../../../assets/img/centerpoint-rtk-sputnik.jpg";

const CenterPointRtk = () => {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1 }}
            exit={{opacity: 0 }}
            transition={{ duration: 0.5 }}
            key="pay"
            className={classes.BlockTarifTabsItem}
        >
            <div className={classes.BlockTarifTabsTop}>
                <div>
                    <b>Доставка поправки</b>
                    <img src={rtkSputnik} alt=""/>
                </div>
                <div>
                    <b>Точность</b>
                    <p> &#60;1" (2,5 см)</p>
                </div>
                <div>
                    <b>Время инициализации</b>
                    <p>&#60;1 минуты</p>
                </div>
            </div>
            <div className={classes.BlockTarifTabsInfo}>
                <p>CenterPoint Trimble RTK идеально подходит для хозяйств в радиусе 8 км от установленной базовой
                    станций RTK или сети базовых станций, без прямой видимости, например участки в холмистой местности
                    или участки засаженные растительностью. CenterPoint RTK хорошо подходит для посадки злаковых и
                    других культур, рыхления полосы, планировки земель и других видов с/х деятельности при которых
                    требуется высокая горизонтальная и вертикальная точность.</p><p>Trimble RTK впервые было
                представлено в начале 1990-х годов, как средство, обеспечивающее высокую точность корректировки в
                полевых условиях. В течение двух десятилетий с тех пор, RTK стал ведущим методом измерений. В
                традиционном RTK используется радиосвязь, которая обеспечивает передачу дифференциальных поправок на
                подвижный приемник. При использовании RTK с радиосвязью, необходимо иметь доступ к базовой станции
                расположенной в 10 км от фермы. Базовая RTK станция посылает коррекции с помощью радиопередатчиков на
                мобильный приемник, подключенный к автомобилю.</p><p>Базовые станции могут быть приобретены для
                отдельных хозяйств, или использоваться по подписке услуга от созданной сети.</p>            </div>
        </motion.div>
    )
}

export default CenterPointRtk;