import React, {useState} from 'react';
import {useMutation} from "@apollo/client";
import Form from "../../../Form/Form";
import ResetSchema from "./ResetForm.schema";
import { RESET_USER } from "../../../../graphql";
import Button from "../../../../ui/Button/Button";
import TextField from "../../../../ui/TextField/TextField";
import Wrapper from "../../../Wrapper/Wrapper";

const ResetForm = ({ stepHandler }) => {
    const [errors, setErrors] = useState();

    const [resetUser, { loading }] = useMutation(RESET_USER, {
        update(_, { data } ) {
            stepHandler("Success")
        },
        onError(err) {
            console.log(err)
        }
    });

    const onSubmit = async (data) =>
        await resetUser({ variables: { ...data }})

    return (
        <Wrapper
            title="Восстановление пароля"
            subscription={"Введите данные указанные при регистрации \nи следуйте дальнейшим инструкциям"}
        >
            <Form
                onSubmit={onSubmit}
                validationSchema={ResetSchema}
            >
                {({ control }) => (
                    <>
                        <TextField
                            name="email"
                            label="Ваш email"
                            control={control}
                            placeholder="Введите email"
                        />
                        <Button type="submit" onClick={onSubmit} disabled={loading}>Восстановить пароль</Button>
                    </>
                )}
            </Form>
        </Wrapper>
    );
};

export default ResetForm;
