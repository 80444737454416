import React from 'react';
import styles from "./AuthNavigator.module.scss";

const AuthNavigator = ({ method, navigator, methodHandler }) => {
    return (
        <div className={styles.navigator}>
            <div className={styles.navigator__container}>
                <div className={styles.navigator__roller} style={{ left: method === "Phone" ? "50%" : "0" }} />
                { navigator.map(item => (
                    <button
                        key={item.key}
                        className={styles.navigator__button}
                        onClick={() => methodHandler(item.key)}
                    >
                        {item.title}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default AuthNavigator;
