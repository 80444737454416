import * as Yup from "yup";

const SubscribeCreateSchema = Yup.object().shape({
  device: Yup.object().shape({
    model: Yup.string().required('Заполните поле'),
    brand: Yup.string().required('Заполните поле'),
    serial: Yup.string().required('Заполните поле'),
  }),
  plan: Yup.string().required('Заполните поле'),
  invoiceType: Yup.string().required('Заполните поле'),
  blocking: Yup.boolean().oneOf([true], 'Заполните поле'),
  situation: Yup.boolean().oneOf([true], 'Заполните поле')
});


export default SubscribeCreateSchema;
