import React from 'react';
import {useForm} from "react-hook-form";
import classes from "./SubscribeIIN.module.scss";
import TextField from "../../../../ui/TextField/TextField";
import Button from "../../../../ui/Button/Button";
import {useMutation} from "@apollo/client";
import {IIN_EXIST, UPDATE_USER_IIN} from "../../../../graphql";

const SubscribeIin = () => {
    const [updateIIN, { loading }] = useMutation(UPDATE_USER_IIN, {
        refetchQueries: [{ query: IIN_EXIST }]
    });

    const { control, handleSubmit } = useForm({
        mode: "onSubmit",
    });

    const onSubmit = handleSubmit(async data => {
        await updateIIN({ variables: { input: { ...data }}})
    });


    return (
        <div className={classes.iin}>
            <div className={classes.iin__title}>
                <h1>У вас не заполнено поле ИИН</h1>
                <h3>ИИН требуется для выдачи документов</h3>
            </div>
            <form encType="multipart/form-data" className={classes.form} onSubmit={onSubmit}>
                <TextField
                    name="iin"
                    label="Ваш ИИН"
                    control={control}
                    placeholder="Укажите ИИН"
                />
                <Button type="submit" disabled={loading} tag="button">Продолжить</Button>
            </form>
        </div>
    );
};

export default SubscribeIin;
