import React, { useMemo } from "react";
import {useSelector} from "react-redux";
import styles from "./SubscribeOrder.module.scss";
import SubscribePay from "../SubscribePay/SubscribePay";
import card from "../../../../assets/img/card.svg";
import Button from "../../../../ui/Button/Button";

const SubscribeOrder = () => {
    const { order } = useSelector(state => state.subscribe);

    const items = useMemo(() => [
        { title: "Тарифный план:", value: order.plan.name },
        { title: "Cрок подписки:", value:  order.plan.time.name },
        { title: "Базовые станции:", value: `${order.stations.length > 0 ? order.stations.map((item) => item.code).join(', ') : 'Все станции'}` },
        { title: `Сумма к оплате ${order.discount && "с учетом скидки"}:`, value: `${order.discount ? order.plan.priceDiscount : order.plan.price} ₸` },
        { title: "Тип оплаты:", value: order.invoice.type === "Bank" ? 'Безналичный расчет' : 'Банковской картой' }
    ], []);


    return (
        <div className={styles.order}>
            <h1>Информация о подписке</h1>
            { items.map((item, index) => (
                <div key={index} className={styles.order__row}>
                    <p>{item.title}</p>
                    <p>{item.value}</p>
                </div>
            )) }

            <SubscribePay _id={order._id}>
                {({ loading, createPay }) => (
                    <div className={styles.order__pay}>
                        <Button onClick={createPay} disabled={loading}>
                            Оплатить картой
                        </Button>
                        <img src={card} alt=""/>
                    </div>
                )}
            </SubscribePay>
        </div>
    );
};

export default SubscribeOrder;
