import * as Yup from "yup";

const phoneNormalize = (phone) => {
    return phone.replace(new RegExp(/[^0-9]/g, "g"), "");
}

const AuthPhoneSchema = Yup.object().shape({
    phone: Yup.string()
        .transform(function (value, originalValue) {
            const phone = phoneNormalize(originalValue);
            const isFullNumber = phone.length === 11;
            return isFullNumber ? phone : null;
        })
        .typeError("Введите верный номер телефона")
        .required("Данное поле обязательно для заполнения")
})

export default AuthPhoneSchema;
