import React, {useState} from "react";
import Modal from "../../../../ui/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
    changePayMethod,
    closeSubscribeProlongModal, setSubscribe
} from "../../../../store/reducers/SubscribeSlice";
import classes from "../SubscribeCreate/SubscribeCreate.module.scss";
import loadingImage from "../../../../assets/img/loading.svg";
import Plan from "../../../UI/Plan/Plan";
import Stations from "../../../UI/Stations/Stations";
import PayField from "../../../../ui/PayFiled/PayField";
import {useForm} from "react-hook-form";
import SubscribeCreateSchema from "../SubscribeCreate/SubscribeCreate.schema";
import {useMutation} from "@apollo/client";
import {PROLONG_SUBSCRIBE} from "../../../../graphql";

const defaultValues = {
    invoiceType: "Card",
    stations: []
}

const SubscribeProlong = ({ stepHandler }) => {
    const dispatch = useDispatch();
    const [stationCount, setStationCount] = useState(0);
    const { prolong: { type, _id } } = useSelector(state => state.subscribe);

    const { control, handleSubmit, setValue } = useForm({
        mode: "onSubmit",
        defaultValues
    });

    const [prolongSubscribe, { loading }] = useMutation(PROLONG_SUBSCRIBE, {
        update(proxy, { data: { prolongSubscribe: prolong } }) {
            dispatch(setSubscribe({ prolong }));
            stepHandler(prolong.invoice.type)
        }
    });

    const onSubmit = handleSubmit(async data => {
        await prolongSubscribe({ variables: { input: { ...data, _id }}})
    });

    return (
        <form encType="multipart/form-data" className={loading ? `${classes.form} ${classes.FormLoading}` : classes.form} onSubmit={onSubmit}>
            <div className={classes.form__loading}>
                <img src={loadingImage} alt=""/>
            </div>
            <div className={classes.form__title}>
                <h1>Продление подписки</h1>
                <span className={classes.GeneralError}>{}</span>
            </div>
            <Plan
                name="plan"
                brandType={type}
                control={control}
                label="Выберите тариф"
                placeholder="Выберите тарифный план"
                onChange={(option) => {
                    setStationCount(option.count);
                    setValue("stations", []);
                }}
            />
            <Stations
                name="stations"
                control={control}
                label="Выберите базу"
                stationCount={stationCount}
                placeholder="Выберите базу"
            />

            <PayField
                control={control}
                name="invoiceType"
                label="Выберите способ оплаты"
                onChange={(option) => dispatch(changePayMethod({ payMethod: option.value }))}
                options={[
                    { name: "Оплата картой", value: "Card" },
                    { name: "Безналичный расчет", value: "Bank" }
                ]}
            />

            <div className={classes.FormButtons}>
                <button className={classes.FormSubmit}>Продолжить</button>
            </div>
        </form>
    )
}


export default SubscribeProlong;
