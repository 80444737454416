import React from "react";
import Modal from "../../../../ui/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import SubscribeCreate from "../SubscribeCreate/SubscribeCreate";
import SubscribeCard from "../SubscribeCard/SubscribeCard";
import SubscribeBank from "../SubscribeBank/SubscribeBank";
import SubscribeFinish from "../SubscribeFinish/SubscribeFinish";
import { changeSubscribeStep, closeSubscribeModal } from "../../../../store/reducers/SubscribeSlice";
import SubscribeProgress from "../SubscribeProgress/SubscribeProgress";
import SubscribeProlong from "../SubscribeProlong/SubscribeProlong";
import SubscribeExpand from "../SubscribeExpand/SubscribeExpand";

const cardVariants = {
    initial: { opacity: 0, y: '-25%' },
    animate: { opacity: 1, y: '0%' },
    exit: { opacity: 0, y: '25%' }
};

const cardVariantsFirst = {
    initial: { opacity: 1, y: '0%' },
    animate: { opacity: 1, y: '0%' },
    exit: { opacity: 0, y: '25%' }
};

const SubscribeContainer = () => {
    const dispatch = useDispatch();
    const { opened, step, prolong: { opened: openedProlong }, expand: { opened: openedExpand } } = useSelector(state => state.subscribe);

    const content = [
        { key: "Prolong", component: (props) => <SubscribeProlong {...props} /> },
        { key: "Expand", component: (props) => <SubscribeExpand {...props} /> },
        { key: "Create", component: (props) => <SubscribeCreate {...props} /> },
        { key: "Card", component: (props) => <SubscribeCard {...props} /> },
        { key: "Bank", component: (props) => <SubscribeBank {...props} /> },
        { key: "Finish", component: (props) => <SubscribeFinish {...props} /> },
        { key: "Demo", component: (props) => <SubscribeFinish {...props} /> },
    ];

    const stepHandler = (step) => dispatch(changeSubscribeStep({ step }));
    const closeHandler = () => dispatch(closeSubscribeModal());


    return (
        <Modal isOpened={opened} onClose={closeHandler}>
            {({ }) => (
                <>
                    {!openedProlong && !openedExpand && <SubscribeProgress /> }
                    <AnimatePresence>
                        { content.map(({ component, key }, index) => (
                            <React.Fragment key={key}>
                                { key === step ? (
                                    <motion.div
                                        exit="exit"
                                        key={key}
                                        animate="animate"
                                        initial="initial"
                                        style={{ width: '100%'}}
                                        variants={key === "Create" ? cardVariantsFirst : cardVariants}
                                    >
                                        { component({
                                            closeHandler,
                                            stepHandler
                                        }) }
                                    </motion.div>
                                ) : null }
                            </React.Fragment>
                        )) }
                    </AnimatePresence>
                </>
            )}
        </Modal>
    )
}


export default SubscribeContainer;
