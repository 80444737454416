import Group from "../Group/Group";
import Label from "../Label/Label";
import Checkbox  from "../Checkbox/Checkbox";
import { useController } from "react-hook-form";

const CheckboxField = ({ name, control, label, options }) => {
  const { field } = useController({ control, name })

  return (
    <Group>
      { label && <Label htmlFor={name}>{label}</Label> }
      { options.map((item, index) => (
        <Checkbox
          name={name}
          value={item.value}
          label={item.label()}
          onChange={field.onChange}
          key={`${item.value}-${index}`}
        />
      )) }
    </Group>
  )
}

export default CheckboxField;
