import React from 'react';
import styles from "./SubscribeFinish.module.scss";
import {useDispatch, useSelector} from "react-redux";

import demoIcon from '../../../../assets/img/demo.svg';
import invoiceIcon from '../../../../assets/img/invoice-payment.svg';
import {closeSubscribeModal, setFinish} from "../../../../store/reducers/SubscribeSlice";

const result = {
    Card: {
        image: invoiceIcon,
        title: 'Спасибо, \n Ваша заявка принята!',
        desc: 'Мы активируем подписку после получение \n оплаты и проверки данных устройства.'
    },
    Bank: {
        image: invoiceIcon,
        title: 'Спасибо, \n Ваша заявка принята!',
        desc: 'В ближайшее время с Вами свяжется\n наш менеджер и предоставит счет на оплату.'
    },
    Demo: {
        image: demoIcon,
        title: 'Спасибо, \n Ваша заявка принята!',
        desc: 'Мы активируем DEMO подписку \n после проверки данных устройства.'
    }
}

const SubscribeFinish = () => {
    const dispatch = useDispatch();
    const { order } = useSelector(state => state.subscribe);

    const closeHandler = () => {
        dispatch(closeSubscribeModal());
        setTimeout(() => {
            dispatch(setFinish())
        }, 750);
    }
    return (
        <div className={styles.finish}>
            <div className={styles.finish__content}>
                <img src={result[order.invoice.type].image} alt="" />
                <h1>{result[order.invoice.type].title}</h1>
                <p>{result[order.invoice.type].desc}</p>
                <button onClick={closeHandler}>Закрыть</button>
            </div>
        </div>
    );
};

export default SubscribeFinish;
