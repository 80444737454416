import React, {useRef, useState} from 'react';
import Select from "react-select";
import classes from './Tab.module.scss';
import DatePicker from "react-datepicker";
import Group from "../../UI/Group/Group";
import { setDefaultLocale, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { DateButton } from "../../UI/Date/Date";
import {useMutation, useQuery} from "@apollo/client";
import {FETCH_RINEX, FETCH_STATIONS_OPTIONS} from "../../../graphql";
import Loading from "../../Loading/Loading";
import {tr} from "date-fns/locale";
import {Input} from "../../UI/Input/Input";

setDefaultLocale('ru', ru);
registerLocale('ru', ru);


const time = [
    {
        label: 'c 00:00 до 00:59',
        value: 'S',
        type: 'pivot',
        time: '0,0,0,0'
    },
    {
        label: 'c 01:00 до 01:59',
        value: 'T',
        type: 'pivot',
        time: '1,0,0,0'
    },
    {
        label: 'c 02:00 до 02:59',
        value: 'U',
        type: 'pivot',
        time: '2,0,0,0'
    },
    {
        label: 'c 03:00 до 03:59',
        value: 'V',
        type: 'pivot',
        time: '3,0,0,0'
    },
    {
        label: 'c 04:00 до 04:59',
        value: 'W',
        type: 'pivot',
        time: '4,0,0,0'
    },
    {
        label: 'c 05:00 до 05:59',
        value: 'X',
        type: 'pivot',
        time: '5,0,0,0'
    },
    {
        label: 'c 06:00 до 06:59',
        value: 'A',
        type: 'pivot',
        time: '6,0,0,0'
    },
    {
        label: 'c 07:00 до 07:59',
        value: 'B',
        type: 'pivot',
        time: '7,0,0,0'
    },
    {
        label: 'c 08:00 до 08:59',
        value: 'C',
        type: 'pivot',
        time: '8,0,0,0'
    },
    {
        label: 'c 09:00 до 09:59',
        value: 'D',
        type: 'pivot',
        time: '9,0,0,0'
    },
    {
        label: 'c 10:00 до 10:59',
        value: 'E',
        type: 'pivot',
        time: '10,0,0,0'
    },
    {
        label: 'c 11:00 до 11:59',
        value: 'F',
        type: 'pivot',
        time: '11,0,0,0'
    },
    {
        label: 'c 12:00 до 12:59',
        value: 'G',
        type: 'pivot',
        time: '12,0,0,0'
    },
    {
        label: 'c 13:00 до 13:59',
        value: 'H',
        type: 'pivot',
        time: '13,0,0,0'
    },
    {
        label: 'c 14:00 до 14:59',
        value: 'I',
        type: 'pivot',
        time: '14,0,0,0'
    },
    {
        label: 'c 15:00 до 15:59',
        value: 'J',
        type: 'pivot',
        time: '15,0,0,0'
    },
    {
        label: 'c 16:00 до 16:59',
        value: 'K',
        type: 'pivot',
        time: '16,0,0,0'
    },
    {
        label: 'c 17:00 до 17:59',
        value: 'L',
        type: 'pivot',
        time: '17,0,0,0'
    },
    {
        label: 'c 18:00 до 18:59',
        value: 'M',
        type: 'pivot',
        time: '18,0,0,0'
    },
    {
        label: 'c 19:00 до 19:59',
        value: 'N',
        type: 'pivot',
        time: '19,0,0,0'
    },
    {
        label: 'c 20:00 до 20:59',
        value: 'O',
        type: 'pivot',
        time: '20,0,0,0'
    },
    {
        label: 'c 21:00 до 21:59',
        value: 'P',
        type: 'pivot',
        time: '21,0,0,0'
    },
    {
        label: 'c 22:00 до 22:59',
        value: 'Q',
        type: 'pivot',
        time: '22,0,0,0'
    },
    {
        label: 'c 23:00 до 23:59',
        value: 'R',
        type: 'pivot',
        time: '23,0,0,0'
    },


    {
        label: 'c 00:00 до 00:59',
        value: 's',
        time: '0,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 01:00 до 01:59',
        value: 't',
        time: '1,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 02:00 до 02:59',
        value: 'u',
        time: '2,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 03:00 до 03:59',
        value: 'v',
        time: '3,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 04:00 до 04:59',
        value: 'w',
        time: '4,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 05:00 до 05:59',
        value: 'x',
        time: '5,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 06:00 до 06:59',
        value: 'a',
        time: '6,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 07:00 до 07:59',
        value: 'b',
        time: '7,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 08:00 до 08:59',
        value: 'c',
        time: '8,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 09:00 до 09:59',
        value: 'd',
        time: '9,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 10:00 до 10:59',
        value: 'e',
        time: '10,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 11:00 до 11:59',
        value: 'f',
        time: '11,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 12:00 до 12:59',
        value: 'g',
        time: '12,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 13:00 до 13:59',
        value: 'h',
        time: '13,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 14:00 до 14:59',
        value: 'i',
        time: '14,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 15:00 до 15:59',
        value: 'j',
        time: '15,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 16:00 до 16:59',
        value: 'k',
        time: '16,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 17:00 до 17:59',
        value: 'l',
        time: '17,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 18:00 до 18:59',
        value: 'm',
        time: '18,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 19:00 до 19:59',
        value: 'n',
        time: '19,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 20:00 до 20:59',
        value: 'o',
        time: '20,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 21:00 до 21:59',
        value: 'p',
        time: '21,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 22:00 до 22:59',
        value: 'q',
        time: '22,0,0,0',
        type: 'ftp'
    },
    {
        label: 'c 23:00 до 23:59',
        value: 'r',
        time: '23,0,0,0',
        type: 'ftp'
    }
];

const initialRinex = {
    startDate: null,
    endDate: null,
    station: {
        value: "",
        type: ""
    },
    errors: []
};

const Rinex = () => {
    const [error, setError] = useState();
    const ref = useRef();
    const [step, setStep] = useState(false);
    const [email, setEmail] = useState("");
    const [rinex, setRinex] = useState(initialRinex);
    const [finish, setFinish] = useState(false);
    const [disableTime, setDisableTime] = useState(true);

    const [fetchRinex, { loading: loadingRinex, data }] = useMutation(FETCH_RINEX, {
        onCompleted: (data) => {
            setFinish(true);
            setTimeout(() => {
                setEmail("");
                setStep(false);
                setFinish(false);
                setRinex(initialRinex)
            }, 2500);
        },
        onError: () => setError(true)
    });

    const { loading, data: { getStations: stations } = {} } = useQuery(FETCH_STATIONS_OPTIONS, {
        variables: { search: "" }
    });

    const validation = () => {
        const errors = {};

        if (rinex.station.type.length <= 0) {
            errors.type = 'Укажите станцию';
        }

        if (!rinex.startDate) {
            errors.date = 'Укажите дату';
        }

        return {
            errors,
            valid: Object.keys(errors).length < 1
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        const { valid, errors } = validation();

        if (!valid) {
            setRinex({
                ...rinex,
                errors: errors
            });
            return false;
        }

        if (valid) {
            setStep(true);
        }
    }

    const onInputChange = (event) => {
        setEmail(event.target.value);
    }

    const sendData = async () => {
        await fetchRinex({
            variables: {
                input: {
                    email: email,
                    startDate: rinex.startDate,
                    endDate: rinex.endDate,
                    station: rinex.station.value
                }
            }
        })
    }

    return (
       <div className={classes.Wrapp}>
           { step && (
               <div className={classes.RinexEmail}>
                   <h3>Введите ваш email адресс, мы отправим ссылку для скачивания.</h3>
                   <p>Формирование файлов в зависимости от выбранного диапазона, процесс может занять от 2-ух до 60 минут...</p>
                   <div className={classes.RinexEmailForm}>
                       <Input
                           name="email"
                           type="text"
                           placeholder="Введите email адресс"
                           onChange={onInputChange}
                       />
                       <button className={classes.RinexDownload} onClick={sendData}>Отправить ссылку</button>
                   </div>
               </div>
           )}
           { finish && (
               <div className={classes.RinexFinish}>
                   <h3>Началось формирование файлов, процесс может занять от 2-ух до 60 минут...</h3>
                   <p>Ссылка будет направлена на следующий email адрес: <span>{email}</span></p>
               </div>
           )}
           { loadingRinex && (
               <div className={classes.RinexLoading}>
                   <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
                       <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                           <animateTransform
                               attributeName="transform"
                               attributeType="XML"
                               type="rotate"
                               dur="1s"
                               from="0 50 50"
                               to="360 50 50"
                               repeatCount="indefinite"
                           />
                       </path>
                   </svg>
                   <p>Формируется архив. Это может занять несколько минут...</p>
               </div>
           )}
           <a href={data?.downloadRinex?.downloadUrl} style={{ display: "none"}} ref={ref} download={data?.downloadRinex?.filename}></a>
           <form className={classes.Rinex} onSubmit={onSubmit}>
               <p>Для скачивания доступны данные за последние 2 месяца</p>
               <Group label="Базовая станция" error={rinex.errors.type}>
                   <Select
                       maxMenuHeight={200}
                       isLoading={loading}
                       isSearchable={true}
                       value={rinex.station || ''}
                       classNamePrefix="select"
                       placeholder="Выберите станцию"
                       onChange={event => {
                           setRinex({
                               ...rinex,
                               station: {
                                   label: event.label,
                                   value: event.value,
                                   type: event.type
                               },
                               errors: []
                           })

                       }}
                       options={!loading && stations.map(station => {
                           return {
                               label: station.code,
                               value: station._id,
                               type: station.type
                           }
                       })}
                   />
               </Group>
               <Group label="Стартовая дата" error={rinex.errors.date} relative={true}>
                   <DatePicker
                       locale="ru"
                       utcOffset={6}
                       showTimeSelect
                       timeIntervals={60}
                       dateFormat="dd.MM.yyyy; H:mm"
                       selected={rinex.startDate}
                       timeCaption="Время начала"
                       placeholderText="Выберите дату"
                       customInput={<DateButton placeholder="Выберите дату" />}
                       onChange={date => setRinex({ ...rinex, startDate: date, errors: [] })}
                       minDate={new Date(new Date().setMonth(new Date().getMonth() - 2))}
                       maxDate={new Date()}
                   />
               </Group>

               <Group label="Конечная дата" relative={true}>
                   <DatePicker
                       locale="ru"
                       utcOffset={-6}
                       selected={rinex.endDate}
                       dateFormat="dd.MM.yyyy; H:mm"
                       showTimeSelect
                       timeIntervals={60}
                       timeCaption="Время окончания"
                       placeholderText="Выберите дату"
                       customInput={<DateButton placeholder="Выберите дату" />}
                       onChange={date => {
                           setDisableTime(true);
                           setRinex({ ...rinex, endDate: date, errors: [] })
                       }}
                       minDate={rinex.startDate}
                       maxDate={new Date()}
                   />
               </Group>
               { !disableTime && (
                   <Group label="Время" error={rinex.errors.time}>
                       <Select
                           maxMenuHeight={200}
                           isSearchable={false}
                           classNamePrefix="select"
                           value={rinex.time || ''}
                           placeholder="Выберите время"
                           options={time.filter(item => item.type === rinex.station.type)}
                           noOptionsMessage={() => <span className={classes.RinexNotice}>Укажите базовую станцию</span>}
                           onChange={event => {
                               setRinex({
                                   ...rinex,
                                   errors: [],
                                   time: {
                                       value: event.value,
                                       label: event.label
                                   }
                               })
                           }}
                       />
                   </Group>
               ) }

               <Group>
                   <button className={classes.RinexButton}>Скачать</button>
               </Group>

               { error &&
                   <div className={classes.RinexError}>
                       <p>Произошла ошибка, файл не найден, обратитесь в службу поддержки.</p>
                   </div>
               }
               {/*<p className={classes.InWork}>*/}
               {/*    Раздел в разработке. <br/>*/}
               {/*    Для запроса RINEX обратитесь в техническую поддержку компании. <br/>*/}
               {/*    <span>Срок хранения файлов 2 месяца.</span>*/}
               {/*</p>*/}
           </form>
       </div>
    )
};

export default Rinex;
