import classes from "../components/Tabs/Tab/Tab.module.scss";

const daysFormatter = (n, text_forms)  => {
    n = Math.abs(n) % 100; let n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 === 1) { return text_forms[0]; }
    return text_forms[2];
}

export const subscribeEnd = (disabledAt) => {
    const days = Math.round((new Date(disabledAt).getTime() - new Date().getTime())/1000/60/60/24);

    return days > 0;
}


export const daysLeft = (disabledAt) => {
    const days = Math.round((new Date(disabledAt).getTime() - new Date().getTime())/1000/60/60/24);

    if (days > 0) {
        return `через ${days} ${daysFormatter(days, ['день', 'дня', 'дней'])}`;
    } else {
        return <p className={classes.Red}>закончилась</p>;
    }
}

export const daysCount = (disabledAt, paidAt) => {
    const days = Math.round((new Date().getTime() - new Date(paidAt).getTime())/1000/60/60/24);


    if (days === 0) {
        return `сегодня`;
    } else if (days < 0) {
        return `через ${days.toString().replace('-', ' ')} ${daysFormatter(days, ['день', 'дня', 'дней'])}`;
    } else  {
        return `${days} ${daysFormatter(days, ['день', 'дня', 'дней'])} назад`;
    }
}
