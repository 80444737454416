import React from 'react';
import classes from "../Rtx.module.scss";
import {motion} from "framer-motion";
import vrsPhone from "../../../assets/img/rtxPhone.png";
import vrsMap from "../../../assets/img/centerpoint-vrs.jpg";

const CenterPointVrs = () => {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ duration: 0.5 }}
            key="pay"
            className={classes.BlockTarifTabsItem}
        >
            <div className={classes.BlockTarifTabsTop}>
                <div>
                    <b>Доставка поправки</b>
                    <img src={vrsPhone} alt=""/>
                </div>
                <div>
                    <b>Точность</b>
                    <p> &#60;1" (2,5 см)</p>
                </div>
                <div>
                    <b>Время инициализации</b>
                    <p>&#60;1 минуты</p>
                </div>
            </div>
            <div className={classes.BlockTarifTabsImage}>
                <img src={vrsMap} alt=""/>
                <div>
                    <p>
                        Ранее известный, как Trimble VRS Now, Trimble CenterPoint VRS позволяет корректировать RTK данные на
                        большом географическом районе, где есть надежная сотовая связь. Trimble CenterPoint VRS обеспечивает
                        высокую точность данных по всей зоне покрытия.
                    </p>
                    <p>
                        Для передачи коррекции применяются такие сотовые модемы связи, как DCM-300 или Ag3000, которые применяются вместо радиомодемов, для получения
                        сантиметровой точности. Сотовая сеть, используемая в CenterPoint VRS основана на сети постоянно
                        действующих базовых станций (CORS). Сеть постоянно действующих базовых станций представляет собой
                        совокупность GPS / GNSS базовых станций расположенных в пределах зоны покрытия для передачи RTK поправок
                        к сотовым модемам на тракторе. Данное решение актуально в районах с пятнистым радиопокрытием.
                    </p>
                </div>
            </div>
        </motion.div>
    )
}

export default CenterPointVrs;