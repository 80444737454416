import React from 'react';
import classes from "./Cabinet.module.scss";
import Tabs from "../../components/Tabs/Tabs";
import Maps from "../../components/Maps/Maps";
import Page from "../../components/Page/Page";
import {useUser} from "../../hooks/useUser";
import SubscribeContainer from "../../components/screens/subscribe/SubscribeContainer/SubscribeContainer";
import SubscribeProlong from "../../components/screens/subscribe/SubscribeProlong/SubscribeProlong";


const Cabinet = () => {
    const { user } = useUser();

    console.log(user)
    return (
        <Page>
            <p className={classes.Code}>ID: {user.login}</p>
            { user?.firstName && <h1 className="title">Уважаемый(ая), {user.lastName} {user.firstName}</h1> }
            <p className={classes.Subtitle}>
                Это ваш личный кабинет, где Вы можете отслеживать статус вашего тарифа и скачивать Rinex.<br />
                Так же мы регулярно будем радовать Вас специальными скидками. Отслеживайте и пользуйтесь всеми преимуществами!
            </p>

            <Tabs />

            <SubscribeContainer />

            <Maps form={false} banner={true} />
        </Page>
    )
};

export default Cabinet;
