import React, {useState} from 'react';
import Form from "../../../Form/Form";
import {useMutation} from "@apollo/client";
import AuthPhoneSchema from "./AuthPhone.schema";
import Button from "../../../../ui/Button/Button";
import {LOGIN_USER_BY_PHONE} from "../../../../graphql";
import TextField from "../../../../ui/TextField/TextField";

export const PHONE_MASK = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

const AuthPhoneForm = ({ stepHandler }) => {
    const [errors, setErrors] = useState();

    const [loginUser, { loading }] = useMutation(LOGIN_USER_BY_PHONE, {
        update(_, { data } ) {
            stepHandler("Otp")
        },
        onError(err) {
            console.log(err)
            //setErrors(err.graphQLErrors[0].extensions.exception.errors);
        }
    });

    const onSubmit = async (data) =>
        await loginUser({ variables: { input: { ...data }}})

    return (
        <Form
            onSubmit={onSubmit}
            validationSchema={AuthPhoneSchema}
        >
            {({ control }) => (
                <>
                    <TextField
                        name="phone"
                        control={control}
                        mask={PHONE_MASK}
                        label="Ваш номер телефона"
                        placeholder="Введите номер телефона"
                    />
                    <Button type="submit" disabled={loading}>Продолжить</Button>
                </>
            )}
        </Form>
    );
};

export default AuthPhoneForm;
