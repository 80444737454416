import {useEffect, useState} from "react";

const ANIMATION_TIME = 500;


export const useMount = ({ opened }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (opened && !mounted) {
      setMounted(true);
    } else if (!opened && mounted) {
      const timeOut = setTimeout(() => {
          setMounted(false)
      }, ANIMATION_TIME);

      return () => clearTimeout(timeOut);
    }
  }, [opened]);

  return {
    mounted
  }
}
