import React from 'react';
import classes from "../Rtx.module.scss";
import {motion} from "framer-motion";
import rtxPhoneMap from '../../../assets/img/rangepoint-rtx.jpg';
import rangeSput from "../../../assets/img/rangepoint-rtx-sputnik.jpg";

const CenterPointRtx = () => {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ duration: 0.5 }}
            key="pay"
            className={classes.BlockTarifTabsItem}
        >
            <div className={classes.BlockTarifTabsTop}>
                <div>
                    <b>Доставка поправки</b>
                    <img src={rangeSput} alt=""/>
                </div>
                <div>
                    <b>Точность</b>
                    <p> &#60;1,5" (3,8 см)</p>
                </div>
                <div>
                    <b>Время инициализации</b>
                    <p>стандартное</p>
                </div>
            </div>
            <div className={classes.BlockTarifTabsImage}>
                <img src={rtxPhoneMap} alt=""/>
                <p>
                    Служба коррекции Trimble CenterPoint RTX обеспечивает передачу GNSS поправок через сотовую сеть, повторяемых каждые 1,5" с плановой точностью 3,8 см. Поправки поступают непосредственно на приемник в любой точке мира. CenterPoint RTX работает с GNSS приемниками, которые имеют встроенный дисплей Trimble FMX, CFX-750 дисплей или GNSS приемник.
                </p>
            </div>
            <div className={classes.BlockTarifTabsInfo}>
                <h3>TRIMBLE CENTERPOINT RTX ДОСТУПЕН ПО ВСЕМУ МИРУ:</h3>
                <ul>
                    <li>Высокая точность - 1,5" (3,8 см) в плане.</li>
                    <li>GNSS совместимость - использование GNSS данных (GPS и ГЛОНАСС) позволяет увеличить
                        производительность полевых бригад.
                    </li>
                    <li>Бесплатная коррекция спутниковых данных ГЛОНАСС - с получением подписки CenterPoint RTX, Доступ
                        к коррекции ГЛОНАСС будет разблокирован бесплатно в течение всего срока подписки.
                    </li>
                    <li>Возможность продолжать работу во время потери коррекции сигнала в течении двух минут.</li>
                    <li>Быстрая инициализация – сходимость с заявленной точностью за 30 минут или меньше. При этом если
                        пользователя устраивает более грубая точность, пользователь может выполнять измерения.
                    </li>
                    <li>Быстрая загрузка - Уменьшение стандартного времени инициализации * менее чем за пять минут при
                        условии, что в начале каждого дня, измерения начинается в том же месте, где приемник был
                        отключен накануне вечером.
                    </li>
                    <li>Отсутствие базовой станции - не нужно бояться потерять прием радио-сигнала, так как базовая
                        станция не нужна.
                    </li>
                    <li>Всемирный доступ к сервису.</li>
                </ul>
            </div>
        </motion.div>
    )
}

export default CenterPointRtx;
