import React from 'react';
import Label from "../Label/Label";
import classes from "./Input.module.scss";
import camera from '../../../assets/img/camera.svg';
import InputMask from 'react-input-mask';

export const Input = ({label, type, placeholder, error, name, value, onChange, disabled}) => {
    const htmlFor = `name- ${Math.random()}`;
    const cls = [classes.Group];
    if (error) {
        cls.push(classes.Error)
    }

    if (type === 'tel') {
        return (
            <InputMask mask="+7 (999) 999-99-99" value={value} error={error} placeholder={placeholder} label={label}
                       htmlFor={htmlFor} onChange={(event) => onChange(event)} autoComplete={`${name}-new`}>
                {(inputProps) => <div className={cls.join(' ')}>
                    <Label htmlForm={inputProps.htmlFor} label={inputProps.label}/>
                    <input type={type} name={name} value={inputProps.value} className={classes.Input}
                           placeholder={inputProps.placeholder} id={inputProps.htmlFor} disabled={disabled} autoComplete={`${name}-new`} />
                    <span className={classes.Message}>{inputProps.error}</span>
                </div>}
            </InputMask>
        )
    } else {
        return (
            <div className={cls.join(' ')}>
                { label && <Label htmlForm={htmlFor} label={label} /> }
                <input type={type} name={name} value={value} className={classes.Input} placeholder={placeholder}
                       id={htmlFor} disabled={disabled} onChange={(event) => onChange(event)} autoComplete={`${name}-new`} />
                { error &&  <span className={classes.Message}>{error}</span> }
            </div>
        )
    }
}


export const InputFile = ({label, error, name, onChange, value}) => {
    const htmlFor = `name- ${Math.random()}`;
    const cls = [classes.Group];
    if (error) {
        cls.push(classes.Error)
    }

    return (
        <div className={cls.join(' ')}>
            <Label htmlForm={htmlFor} label={label} />
            <label htmlFor={htmlFor} className={[classes.File, value && classes.FileChanged].join(' ')}>
                {!value ? 'Фото не выбранно...' : value}
                <span>
                    <img src={camera} alt=""/>
                    Выберите файл
                </span>
            </label>
            <input type="file" name={name} id={htmlFor} onChange={(event) => onChange(event)}/>
            <span className={classes.Message}>{error}</span>
        </div>
    )
}