import React, {useEffect, useState} from 'react';
import classes from './SubscribeCreate.module.scss';
import {useDispatch} from "react-redux";
import loadingImage from "../../../../assets/img/loading.svg";
import Plan from "../../../UI/Plan/Plan";
import Brand from "../../../Brand/Brand";
import Stations from "../../../UI/Stations/Stations";
import { useMutation } from '@apollo/client';
import { useForm } from "react-hook-form";
import { CREATE_SUBSCRIBE } from "../../../../graphql";
import TextField from "../../../../ui/TextField/TextField";
import PayField from "../../../../ui/PayFiled/PayField";
import { yupResolver } from "@hookform/resolvers/yup";
import SubscribeCreateSchema from "./SubscribeCreate.schema";
import CheckboxField from "../../../../ui/CheckboxField/CheckboxField";
import pdf from '../../../../assets/pdf/Положение_об_использовании_базовых_станций.pdf';
import {changePayMethod, setSubscribe} from "../../../../store/reducers/SubscribeSlice";
import FileField from "../../../../ui/FileField/FileField";

const defaultValues = {
    invoiceType: "Card",
    stations: []
}

const SubscribeCreate = ({ stepHandler }) => {
    const dispatch = useDispatch();
    const [demo, setDemo] = useState(false);
    const [brandType, setBrandType] = useState("");
    const [stationCount, setStationCount] = useState(0);

    const { control, handleSubmit, setValue, watch } = useForm({
        mode: "onSubmit",
        defaultValues,
        resolver: yupResolver(SubscribeCreateSchema)
    });

    const [createSubscribe, { loading }] = useMutation(CREATE_SUBSCRIBE, {
        update(proxy, { data: { createSubscription: order } }) {
            dispatch(setSubscribe({ order }));
            stepHandler(order.invoice.type)
        },
        onCompleted(data) {
            console.log(data);
        },
        onError(err) {
            //setErrors({ ...errors, ...err.graphQLErrors[0].extensions.exception.errors});
        }
    });

    const onSubmit = handleSubmit(async data => {
        const { situation, blocking, ...values } = data;
        console.log(values)
        await createSubscribe({ variables: { input: { ...values }}})
    });

    useEffect(() => {
        if (!demo) return;
        setValue("invoiceType", "Demo")
    }, [demo]);

    return (
        <form encType="multipart/form-data" className={loading ? `${classes.form} ${classes.FormLoading}` : classes.form} onSubmit={onSubmit}>
            <div className={classes.form__loading}>
                <img src={loadingImage} alt=""/>
            </div>
            <div className={classes.form__title}>
                <h1>Регистрация приемника</h1>
                <span className={classes.GeneralError}>{}</span>
            </div>
            <Brand
                control={control}
                name="device.brand"
                label="Марка приемника"
                placeholder="Выберите марку из списка"
                onChange={(event) => setBrandType(event.type)}
            />

            <TextField
                control={control}
                name="device.model"
                label="Модель приемника"
                placeholder="Укажите модель приемника"
            />

            <TextField
                control={control}
                name="device.serial"
                label="Серийный номер"
                placeholder="Укажите серийный номер приемника"
            />

            <FileField
                name={"file"}
                control={control}
                label="Фото приемника"
                acceptFiles={{
                    'image/*': ['.jpeg', '.jpg', '.png'],
                }}
            />

            <Plan
                name="plan"
                control={control}
                brandType={brandType}
                label="Выберите тариф"
                placeholder="Выберите тарифный план"
                onChange={(option) => {
                    setStationCount(option.count);
                    setValue("stations", []);
                    setDemo(option.type === "DEMO");
                }}
            />

            <TextField
                name="promoCode"
                control={control}
                label="Введите промо-код"
                placeholder="Укажите промо-код"
            />

            <Stations
                name="stations"
                control={control}
                label="Выберите базу"
                stationCount={stationCount}
                placeholder="Выберите базу"
            />

            { !demo && (
                <PayField
                    control={control}
                    name="invoiceType"
                    label="Выберите способ оплаты"
                    onChange={(option) => dispatch(changePayMethod({ payMethod: option.value }))}
                    options={[
                        { name: "Оплата картой", value: "Card" },
                        { name: "Безналичный расчет", value: "Bank" }
                    ]}
                />
            ) }

            <CheckboxField
                control={control}
                name="situation"
                options={[
                    {
                        value: true,
                        label: () => ( <>Я прочитал(а) и принимаю <a href={pdf} target="_blank" rel="noreferrer">Положение об использовании <br />базовых станций компании “Геокурс”</a></>)
                    },
                ]}
            />
            <CheckboxField
                control={control}
                name="blocking"
                options={[
                    {
                        value: true,
                        label: () => ( <>Я предупрежден(а), что за передачу третьи лицам данных авторизации (логин и пароль) и сведений о базовых станциях - пожизненная блокировка прибора</>)
                    }
                ]}
            />

            <div className={classes.FormButtons}>
                <button className={classes.FormSubmit}>Продолжить</button>
            </div>
        </form>
    )
};

export default SubscribeCreate;

