import gql from "graphql-tag";

export const FETCH_BRANDS = gql`
    query getBrand {
        getReceivers {
            _id
            type
            label
            value
        }
    }
`;

export const FETCH_PLAN = gql`
    query Plan ($id: String!) {
        getBsPlan(id: $id) {
            label
            price
            time {
                name
            }
            device {
                item
            }
        }
    }
`;


export const FETCH_MAP_STATIONS = gql`
    query getStationList($filter: StationsFilter) {
        getStations(filter: $filter) {
            _id
            city
            code
            status
            satellite
            cords {
                e
                n
            }
            position {
                lon
                lat
                alt
            }
            activated
        }
    }
`;

export const FETCH_TABLE_STATIONS = gql`
    query getStations($filter: StationsFilter) {
        getStations(filter: $filter) {
            _id
            city
            code
            status
            satellite
            cords {
                e
                n
            }
            position {
                lon
                lat
                alt
            }
            activated
        }
    }
`;


export const LOGIN_USER_BY_PHONE = gql`
    mutation userLoginByPhone($input: LoginByPhoneInput!) {
        userLoginByPhone(input: $input) {
           status
           step
           verifyHash
        }
    }
`;

export const CREATE_REQUEST_STATION = gql`
    mutation requestStation($input: StationRequestInput!) {
        requestStation(input: $input)
    }
`;



export const LOGIN_USER_BY_EMAIL = gql`
    mutation userLoginByEmail($input: LoginByEmailInput!) {
        userLoginByEmail(input: $input) {
            firstName
            lastName
            email
            login
            accessToken
            refreshToken
        }
    }
`;

export const VERIFY_OTP_CODE = gql`
    mutation userVerifyOtp($input: VerifyInput!) {
        userVerifyOtp(input: $input) {
            firstName
            lastName
            email
            login
            accessToken
            refreshToken
        }
    }
`;


export const REGISTER_USER = gql`
    mutation register(
        $name: String!
        $lastname: String!
        $company: String!
        $city: String!
        $phone: String!
        $email: String!
    ) {
        register(
            registerInput: {
                name: $name
                lastname: $lastname
                company: $company
                city: $city
                phone: $phone
                email: $email
            }
        ) {
            id email name createdAt token
        }
    }
`;


export const STATION_REQUEST = gql`
    mutation questBsStation(
        $city: String!
        $fio: String!
        $phone: String!
        $confirm: Boolean!
    ) {
        questBsStation(
            city: $city
            fio: $fio
            phone: $phone
            confirm: $confirm
        )
    }
`;


export const FETCH_PLANS_OPTIONS = gql`
    query getSelectPlans($filter: PlanFilterInput) {
        getPlans(filter: $filter) {
            _id
            name
            price
            station {
                name
            }
            time {
                name
                enumCount
            }
            station {
                name
                enumCount
            }
        }
    }
`;

export const FETCH_RINEX = gql`
    mutation downloadRinex($input: RinexInput!) {
        downloadRinex(input: $input) {
            type
            code
            location
            filename
            downloadUrl
        }
    }
`;


export const FETCH_STATIONS_OPTIONS = gql`
    query getStationList($filter: StationsFilter) {
        getStations(filter: $filter) {
            _id
            code
            type
            city
            location
        }
    }
`;


export const FETCH_DEVICE = gql `
    query getUserSubscriptions {
        getUserSubscriptions {
            _id
            device {
                code
                brand
                model
                serial
            }
            plan {
                _id
                name
                device {
                    type
                }
                price
                time {
                    name
                }
                station {
                    enumCount
                }
            }
            date {
                activatedAt
                disabledAt
            }
            access {
                login
                status
                password
            }
            stations {
                _id
                city
                code
            }
            invoice {
                _id
                type
                status
            }
            status {
                type
            }
        }
    }
`;

export const FETCH_SERVICE = gql `
    query getExpandSubscribe {
        getSubscriptionService {
            _id
            title
            price
            description
        }
    }
`;


export const RESET_USER = gql `
    mutation userResetPassword(
        $email: String!
    ) {
        userResetPassword(email: $email)
    }
`;

export const EXPAND_SUBSCRIBE = gql `
    mutation expandSubscribe($input: ServiceSetInput!) {
        setSubscriptionService(input: $input) {
            id
            location
            payment_page_url
        }
    }
`;


export const CHANGE_PASSWORD = gql `
    mutation userChangePassword(
        $input: ChangePasswordInput!
    ) {
        userChangePassword(input: $input)
    }
`;


export const LOGOUT_USER = gql `
    mutation userLogout {
        userLogout 
    }
`;


export const FETCH_USER = gql `
    query userAuth {
        userAuth {
            role
            login
            city
            company
            lastName
            firstName
            phone
            email
            accessToken
            refreshToken
            permissions
        }
    }
`;

export const UPDATE_USER = gql`
    mutation edit(
        $name: String!
        $lastname: String!
        $company: String!
        $city: String!
        $phone: String!
        $email: String!
    ) {
        edit(
            editInput: {
                name: $name
                lastname: $lastname
                company: $company
                city: $city
                phone: $phone
                email: $email
            }
        ) {
            id name lastname company city phone email
        }
    }
`;


export const FETCH_PLANS = gql`
    query getPlanList($filter: PlanFilterInput!) {
        getPlans(filter: $filter) {
            _id
            name
            price
            activated
            time {
                name
                enumCount
            }
            device {
                name
                type
            }
            station {
                name
                enumCount
            }
        }
    }
`;


export const GET_USER = gql`
    {
        getUser {
            id
            name
            lastname
            demo
        }
    }
`;

export const IIN_EXIST = gql`
    query getUserIin {
        userIinExist {
            iin
        }
    }
`;

export const CREATE_REQUISITE = gql`
    mutation createRequisite($input: RequisitesCreateInput!) {
        createRequisite(input: $input) {
            _id
            companyAddress
            companyName
            companyBin
            bankRequisites
            bankName
        }
    }
`;

export const GET_REQUISITE = gql`
    query getUserRequisite {
        getUserRequisite {
            _id
            bankName
            default
            bankRequisites
            companyBin
            companyName
            companyAddress
        }
    }
`;

export const CHANGE_DEFAULT_REQUISITE = gql`
    mutation changeDefaultRequisite($input: RequisitesSetDefaultInput!) {
        changeDefaultRequisite(input: $input) {
            _id
            bankName
            default
            bankRequisites
            companyBin
            companyName
            companyAddress
        }
    }

`;

export const CREATE_PAY_SUBSCRIBE = gql`
    query paySubscription($input: SubscriptionPayInput!) {
        paySubscription(input: $input) {
            id
            location
            payment_page_url
        }
    }
`;

export const CREATE_SUBSCRIBE = gql`
    mutation createSubscribe($input: SubscriptionCreateInput!) {
        createSubscription(input: $input) {
            _id
            discount
            plan {
                _id
                name
                price
                price
                priceDiscount
                time {
                    name
                }
            }
            invoice {
                type
            }
            stations {
                _id
                code
                city
                status
                satellite
                position {
                    lat
                    lon
                    alt
                }
            }
        }
    }
`;


export const PROLONG_SUBSCRIBE = gql`
    mutation prolongSubscribe($input: SubscriptionProlongInput!) {
        prolongSubscription(input: $input) {
            _id
            discount
            plan {
                _id
                name
                price
                price
                priceDiscount
                time {
                    name
                }
            }
            invoice {
                type
            }
            stations {
                _id
                code
                city
                status
                satellite
                position {
                    lat
                    lon
                    alt
                }
            }
        }
    }

`;

export const UPDATE_USER_IIN = gql`
    mutation updateUserIin($input: UserUpdateInput!) {
        editUserProfile(input: $input) {
            _id
            iin
        }
    }
`;


export const UPDATE_SUBSCRIBE = gql`
    mutation updateBsSubscription(
            $id: ID!
            $plan: SubscriptionInputPlan!
            $station: SubscriptionInputStation!
            $pay: SubscriptionInputPay!
    ) {
        updateBsSubscription(
            subscribeUpdateInput: {
                id: $id
                pay: $pay
                plan: $plan
                station: $station
            }
        ) {
            id
            device {
                id
                brand
                model
                serial
            }
            pay {
                method
                paid
            }
            date {
                createdAt
                paidAt
                disabledAt
            }
            plan {
                id
                label
                amount
            }
        }
    }
`;


export const CREATE_INVOICE = gql`
    mutation createBsInvoice(
        $bin: String!
        $inv: String!
        $bank: String!
        $address: String!
        $orderId: String!
        $device: String!
        $plan: String!
    ) {
        createBsInvoice(
            invoiceInput: {
                bin: $bin
                inv: $inv
                address: $address
                bank: $bank
                orderId: $orderId
                device: $device
                plan: $plan
            }
        ) {
            id
            inv
            bank
            orderId
            device
        }
    }
`;


export const CREATE_PAY = gql`
    mutation pay(
        $orderId: String!
        $deviceId: String!
        $planId: String!
    ) {
        pay(
            payInput: {
                orderId: $orderId
                deviceId: $deviceId
                planId: $planId
            }
        ) {
            id
            location
            payment_page_url
        }
    }
`

export const FETCH_INSTRUCTIONS = gql`
    query getSubscriptionInstructions($filter: SubscriptionInstructionFilter!) {
        getSubscriptionInstructions(filter: $filter) {
            _id
            file
            label
            filename
            activated
            createdAt
        }
    }
`
