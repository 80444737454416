import React from 'react';
import classes from "../Tabs/Tab/Tab.module.scss";
import passwordGreen from '../../assets/img/password-green.svg';
import passwordOrange from '../../assets/img/password-orange.svg';
import ReactTooltip from "react-tooltip";
import { DateStart, DateEnd } from '../UI/Date/Date';
import SubscribePay from "../screens/subscribe/SubscribePay/SubscribePay";
import {useDispatch} from "react-redux";
import {openSubscribeModal, openSubscribeExpandModal, openSubscribeProlongModal} from "../../store/reducers/SubscribeSlice";
import styles from "../screens/subscribe/SubscribeOrder/SubscribeOrder.module.scss";
import Button from "../../ui/Button/Button";

export const Subscriptions = ({ subscriptions }) => {
    const dispatch = useDispatch();
    return (
        <>
            { subscriptions.map((item, index) => {
                console.log(item.device.code)
                console.log(item.invoice.status, item.status.type, item.plan.station.enumCount)
                console.log(item.invoice.status === "Paid" && item.status.type === "ACTIVE" && item.plan.station.enumCount === "THREE")
                return (
                    <tr key={item.id}>
                        <td className={classes.Password}>
                            <button data-tip={item.device.code} data-for={item.device.code} data-class={classes.PasswordTooltip} data-event='click' className={!item.access.status && classes.PasswordHide}>
                                <img src={item.access.status ? passwordGreen : passwordOrange } alt=""/>
                            </button>

                            { item.access.status &&
                                <ReactTooltip id={item.device.code} place='top' effect='solid' backgroundColor='#197323' clickable={true}>
                                    <span>Доступы для подключения:</span>
                                    <ul>
                                        <li>Логин: {item.access.login}</li>
                                        <li>Пароль: {item.access.password}</li>
                                    </ul>
                                </ReactTooltip>
                            }
                        </td>
                        <td>
                            <p>{item.device.code}</p>
                        </td>
                        <td>
                            <h2>{item.device.brand} {item.device.model}</h2>
                            <p data-tip="hello world">№: {item.device.serial}</p>
                        </td>
                        <td>
                            <h3>«{item.plan.name}»</h3>
                            <p>{item.plan.price > 0 ? `${item.plan.price}тг` : 'бесплатно'} </p>
                        </td>
                        <td>
                            <DateStart date={item.date} />
                        </td>
                        <td>
                            <DateEnd date={item.date} plan={item.plan} />
                        </td>
                        <td>
                            <StatusPay device={item} />
                            {item.invoice.status === "Paid" && item.status.type === "ACTIVE" && item.plan.station.enumCount === "THREE" && (
                                <p className={styles.prolong} onClick={() => {
                                    dispatch(openSubscribeModal("Expand"));
                                    dispatch(openSubscribeExpandModal(item._id));
                                }}>Расширить подписку</p>
                            ) }
                        </td>
                    </tr>
                )
            }) }
        </>
    )
};


export const status = {
    BILL_CREATE: "Ожидает счет",
    BILL_PAY: "Счет выставлен",
    CARD_PAY: "Ожидается оплата",
    CARD_DOC: "Готовятся документы",
    ACTIVE: "Активна",
    ACCESS: "Ожидает доступы",
    ENDED: "Закончилась",
    ARCHIVE: "В архиве",
    COMING: "Ожидает"
};


export const StatusPay = ({ device }) => {
    const dispatch = useDispatch();

    function Waiting(text) {
        return (
            <>
                <i className={classes.Orange} />
                <p className={classes.Orange}>{text}</p>
            </>
        )
    }

    function StatusPaid() {
        return (
            <>
                <i className={classes.Green} />
                <p className={classes.Green}>Оплачен</p>
            </>
        )
    }



    function ProlongButton() {
        return (
            <button className={classes.ButtonProlong} onClick={() => {
                dispatch(openSubscribeModal("Prolong"));
                dispatch(openSubscribeProlongModal({ type: device.plan.device.type, _id: device._id }));
            }}>
                Продлить подписку
            </button>
        )
    }

    function PayButton() {
        return (
            <SubscribePay _id={device._id}>
                {({ loading, createPay }) => (
                     <Button onClick={createPay} disabled={loading} className={classes.ButtonPay}>
                        Оплатить картой
                     </Button>
                )}
            </SubscribePay>
        )
    }


    if (device.status.type === "ACTIVE") {
        return StatusPaid();
    }

    if (device.status.type === "ENDED") {
        return ProlongButton();
    }

    if (device.status.type === "CARD_PAY") {
        return PayButton();
    }

    return Waiting(status[device.status.type]);
}
