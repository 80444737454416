import React from 'react';
import Label from "../Label/Label";
import classes from "../Input/Input.module.scss";

const Group = ({ relative = false, label, error, children, flex, full = true }) => {
    const htmlFor = `name- ${Math.random()}`;

    const cls = [classes.Group, full && flex && classes.GroupFlex, relative && classes.Relative];
    if (error) { cls.push(classes.Error) }

    return (
        <div className={cls.join(' ')}>
            { label && <Label htmlForm={htmlFor} label={label} /> }
            { children }
            { error && <span className={classes.Message}>{error}</span> }
        </div>
    )
};

export default Group;