import React, {useState} from 'react';
import classes from './Page.module.scss';
import Loader from 'react-loader-spinner'
import { motion } from "framer-motion";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Menu from "../Menu/Menu";

const Page = (props) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <main className={classes.Main}>
            <Navbar setOpen={() => setIsActive(prevState => !prevState)} isOpen={isActive} />

            <Menu status={isActive} />

            <div className={classes.Wrapper}>
                { props.loading ?
                    <div className={classes.Loader}>
                        <Loader
                            type="Circles"
                            color="#197323"
                            height={50}
                            width={50}
                            timeout={50000}
                        />
                    </div>
                    : <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                    >
                        {props.children}
                    </motion.div>
                }
            </div>
            <Footer />
        </main>
    )
}

export default Page;
