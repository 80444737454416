import {useNavigate} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {LOGOUT_USER} from "../../graphql";
import styles from "./Logout.module.scss";

const Logout = () => {
    const navigate = useNavigate();
    const [logout] = useMutation(LOGOUT_USER);

    const logoutHandler = () => logout().then(() => {
        localStorage.removeItem('accessToken');
        navigate("/")
    });

    return (
        <button onClick={logoutHandler} className={styles.logout}>Выйти</button>
    )
}


export default Logout;
