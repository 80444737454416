import React from 'react';
import classes from './Stations.module.scss';
import Page from "../../components/Page/Page";
import { useQuery } from "@apollo/client";
import { Table, Tr, Td } from "../../components/Table/Table";
import { FETCH_TABLE_STATIONS } from "../../graphql";


const Stations = () => {
    const { loading, data: { getStations: stations } = {} } = useQuery(FETCH_TABLE_STATIONS, {
        variables: { search: "" }
    });

    return (
        <Page loading={loading}>
            <h1 className="title">Список станций</h1>

            <Table value={[
                {name: "id", label: '№'},
                {name: "code", label: 'Код'},
                {name: "city", label: 'Населенный пункт'},
                {name: "cords", label: 'Расположение'},
                {name: "satellite", label: 'Спутниковые группировки'},
                {name: "status", label: 'Статус'}
            ]}>
                { stations && stations.map((station, index) => (
                    <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{station.code}</Td>
                        <Td>{station.city}</Td>
                        <Td>
                            Широта: {station.position.lat}"<br/>
                            Долгота: {station.position.lon}"<br/>
                            Высота: {station.position.alt} м
                        </Td>
                        <Td>{station.satellite}</Td>
                        <Td className={classes.Status}>
                            {station.status === true ? (
                                <><span className={classes.Online}/> Работает</>
                            ) : (
                                <><span className={classes.Offline}/> Временно отключена</>
                            )}
                        </Td>
                    </Tr>
                )) }
            </Table>
            <p className={classes.TableHint}>В рамках проекта модернизации базовых станций Геокурс, ведутся работы по установке и обновлению 32 базовых станций. Новые станции имеют поддержку спутниковых систем GPS, GLONASS, BEIDOU, GALILEO, QZSS</p>
        </Page>
    )
};

export default Stations;

