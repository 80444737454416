import React from 'react';
import { useQuery } from "@apollo/client";
import { FETCH_PLANS_OPTIONS } from "../../../graphql";
import SelectField from "../../../ui/SelectField/SelectField";

const counter = (enumCount) => {
    switch (enumCount) {
        case "THREE":
            return 3;
        case "ONE":
            return 1;
        case "ALL":
            return 0;
        default:
            return 0;
    }
}

const Plan = ({ brandType, onChange, ...props }) => {
    const { loading, data: { getPlans: plans } = {} } = useQuery(FETCH_PLANS_OPTIONS, {
        nextFetchPolicy: "cache-and-network",
        variables: { filter: { device: { type: brandType } }}
    });

    if (!brandType) return null;

    return (
        <SelectField
            {...props}
            loading={loading}
            onChange={(option) => onChange(option)}
            options={plans && plans.map(item => {
                return {
                    value: item._id,
                    type: item.time.enumCount,
                    count: counter(item.station.enumCount),
                    label: `${item.name} (${item.station.name}) (${item.time.name})`
                }
            })}
        />
    )
};

export default Plan;

