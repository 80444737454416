import React from 'react';
import { useQuery } from "@apollo/client";
import { FETCH_BRANDS } from "../../graphql";
import SelectField from "../../ui/SelectField/SelectField";


const Brand = ({ control, name, label, placeholder, onChange }) => {
    const { loading, data: { getReceivers: brands } = {} } = useQuery(FETCH_BRANDS);

    return (
        <SelectField
            name={name}
            label={label}
            control={control}
            loading={loading}
            placeholder={placeholder}
            onChange={(option) => onChange(option)}
            options={brands && brands.map(item => {
                return {
                    type: item.type,
                    label: item.label,
                    value: item.label
                }
            })}
        />
    )
};

export default Brand;
