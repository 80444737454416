import Group from "../Group/Group";
import Input from "../Input/Input";
import Label from "../Label/Label";
import Error from "../Error/Error";
import { useController } from "react-hook-form";

const TextField = ({
    mask = undefined,
    name,
    label,
    control,
    ...props
}) => {
    const { field, fieldState: { error } } = useController({ control, name });

    return (
        <Group>
            { label && <Label htmlFor={field.name}>{label}</Label> }
            <Input
                mask={mask}
                isError={!!error}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                {...props}
            />
            { error && <Error message={error.message} /> }
        </Group>
    )
}


export default TextField;
