import React from 'react';
import classes from "../Rtx.module.scss";
import {motion} from "framer-motion";
import rtxMap from "../../../assets/img/centerpoint-rtx-pp.jpg";

const RangePointRtxPost = () => {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ duration: 0.5 }}
            key="pay"
            className={classes.BlockTarifTabsItem}
        >
            <div className={classes.BlockTarifTabsImage}>
                <img src={rtxMap} alt=""/>
                <div>
                    <p>
                        Файлы статических измерений могут быть обработаны на открытом веб-сервисе www.TrimbleRTX.com с сантиметровой точностью. Данная услуга доступна для пользователей по всему миру.
                    </p>
                </div>
            </div>
            <div className={classes.BlockTarifTabsInfo}>
                <h3>Как это работает?</h3>
                <p>
                    Файлы форматов RINEX, T01 или T02 подгружаются на сайт TrimbleRTX.com. После успешной передачи, файл
                    измерений обрабатывается относительно базовых станций компании Trimble входящие в состав технологии
                    RTX, после чего обработанный файл высылается на электронную почту пользователя, которую он указывает
                    перед подгрузкой файла.
                </p>
                <p>
                    Данная технология от компании Trimble использует данные глобальной сети станций слежения, наряду с инновационным позиционированием и алгоритмом сжатия для расчета орбиты
                    спутника, погрешность часов спутника и другие параметры. системы к приемнику, в результате высокой
                    точностью. Коррекции вычисляются с помощью веб-сервиса доставки для постобработки.
                </p>
            </div>
        </motion.div>
    )
}

export default RangePointRtxPost;