import React from 'react';
import {clsx} from "clsx";
import styles from "./Button.module.scss";

const Button = ({ children, type = "button", tag = "button", theme = "default", disabled = false, className, onClick = () => null }) => {
    const classNames = clsx([styles.button, className], {
        [styles.button_disabled]: disabled,
        [styles.button_theme_default]: theme === "default",
        [styles.button_theme_second]: theme === "second",
    })

    return React.createElement(tag, {
        type,
        onClick,
        className: classNames
    }, children)
};

export default Button;
