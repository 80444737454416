import React from "react";
import { Outlet, Navigate } from "react-router-dom";


const RedirectRoute = ({ to }) => {
    const isAuth = localStorage.getItem('accessToken');

    return isAuth ? <Navigate to={to} /> : <Outlet />;
}

export default RedirectRoute;
