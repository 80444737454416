import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    order: null,
    opened: false,
    step: "Create",
}

const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        openRequestModal: (state) => {
            state.opened = true;
        },
        closeRequestModal: (state) => {
            state.opened = false;
        },
        changeRequestStep: (state, action) => {
            state.step = action.payload.step;
        },
        setFinish: (state) => {
            state.step = "Create";
        }
    },
})

export const { openRequestModal, closeRequestModal, changeRequestStep, setFinish } = requestSlice.actions;
export default requestSlice.reducer;
