import React, { useState } from "react";
import AuthOtpForm from "./AuthOtpForm";
import AuthPhoneForm from "./AuthPhoneForm";
import {AnimatePresence, motion} from "framer-motion";

const cardVariants = {
    initial: { opacity: 0, x: '25%' },
    animate: { opacity: 1, x: '0%' },
    exit: { opacity: 0, x: '-25%' }
};

const AuthWithPhone = () => {
    const [step, setStep] = useState("Phone");
    const stepHandler = (step) => setStep(step);

    const content = [
        { key: "Phone", component: (props) => <AuthPhoneForm {...props} /> },
        { key: "Otp", component: (props) => <AuthOtpForm {...props} /> },
    ];

    return (
        <AnimatePresence>
            { content.map(({ component, key }, index) => (
                <React.Fragment key={key}>
                    { key === step ? (
                        <motion.div
                            exit="exit"
                            key={key}
                            animate="animate"
                            initial="initial"
                            variants={cardVariants}
                            style={{ width: '100%'}}
                        >
                            { component({
                                stepHandler
                            }) }
                        </motion.div>
                    ) : null }
                </React.Fragment>
            )) }
        </AnimatePresence>
    );
};

export default AuthWithPhone;
