import React from 'react';
import classes from './Footer.module.scss';

import policy from '../../assets/pdf/Политика_в_отношении_обработки_персональных_данных.pdf';
import offer from '../../assets/pdf/Положение_о_получении_доступа_к_базовым_GNSS_станциям.pdf';
import card from '../../assets/img/card.svg';

const Footer = () => {
    return (
        <footer className={classes.Footer}>
            <ul className={classes.Links}>
                <li><a href={policy} target="_blank" rel="noreferrer">Политика конфиденциальности</a></li>
                <li><a href={offer} target="_blank" rel="noreferrer">Публичная оферта</a></li>
            </ul>
            <div className={classes.Logos}>
                <p>Cпособы оплаты:</p>
                <ul>
                    <li><img src={card} alt=""/></li>
                </ul>
                <span>Пластиковой картой,<br /> счет на оплату, наличными в кассу.</span>
            </div>
        </footer>
    )
};

export default Footer;