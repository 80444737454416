import React from "react";
import {Route, Routes} from "react-router-dom";

import Rtx from "../../pages/Rtx/Rtx";
import Home from "../../pages/Home/Home";
import Plans from "../../pages/Plans/Plans";
import Cabinet from "../../pages/Cabinet/Cabinet";
import Stations from "../../pages/Stations/Stations";
import Instructions from "../../pages/Instructions/Instructions";


import Policy from "../../pages/Policy/Policy";
import Account from "../../pages/Account/Account";
import RedirectRoute from "../RedirectRoute/RedirectRoute";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";


const AppRoute = () => {
  return (
      <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/stations" element={<Stations />}/>
          <Route path="/instructions" element={<Instructions />}/>
          <Route path="/plans" element={<Plans />}/>
          <Route path="/rtx" element={<Rtx />}/>
          <Route path="/policy" element={<Policy />}/>

          <Route element={<RedirectRoute to="/cabinet" />}>
              <Route path="/account" element={<Account />} />
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route path="/cabinet" element={<Cabinet />}/>
          </Route>
      </Routes>
  )
}

export default AppRoute;
