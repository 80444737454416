import React from 'react';
import styles from "./Select.module.scss";
import checkbox from "../../assets/img/check.svg";


const SelectOption = ({ option, selected, onChange }) => {
  const changeHandler = () => onChange(option);

  return (
    <li
      role="option"
      tabIndex={0}
      key={option.label}
      onClick={changeHandler}
      className={styles.options__item}
    >
      <div className={styles.options__label}>{option.label}</div>
      { selected && <div className={styles.options__selected}><img src={checkbox} alt=""/></div> }
    </li>
  );
};

export default SelectOption;
