import React from 'react';
import AppRoute from "./routes/AppRoute/AppRoute";


function App() {
    return (
        <AppRoute />
    );
}

export default App;
