import React from 'react';
import classes from "./Banner.module.scss";
import bannerImage from "../../assets/img/banner.png";

const Banner = () => {
    return (
        <div className={classes.Banner}>
            <a href="https://geokurs.kz/services/arenda/gnss" target="_blank" rel="noreferrer">
                <img src={bannerImage} alt=""/>
            </a>
        </div>
    )
};

export default Banner;