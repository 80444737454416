import styles from "./Account.module.scss";
import Page from "../../components/Page/Page";
import React, {useCallback, useEffect, useState} from 'react';
import AuthContainer from "../../components/screens/auth/AuthContainer/AuthContainer";
import {AnimatePresence, motion} from "framer-motion";
import ResetContainer from "../../components/screens/reset/ResetContainer/ResetContainer";
import RegisterContainer from "../../components/screens/register/RegisterContainer";
import {useLocation} from "react-router-dom";
import ChangeContainer from "../../components/screens/change/ChangeContainer/ChangeContainer";


const cardVariants = {
    initial: { opacity: 0, y: '25%' },
    animate: { opacity: 1, y: '0%' },
    exit: { opacity: 0, y: '-25%' }
};

const Account = () => {
    const [type, setType] = useState("Auth");

    const location = useLocation();
    const hashTab = location.hash.replace('#', '') || 'Auth';

    useEffect(() => {
        setType(new URLSearchParams(location.search).get("type") || "Auth");
    }, [hashTab])

    const content = [
        { key: "Auth", title: "Личный кабинет", desc: "Для продолжения необходимо авторизоваться, выберите удобный способ:", component: (props) => <AuthContainer {...props} /> },
        { key: "Reset", title: "Восстановление пароля", desc: "Введите данные указанные при регистрации и следуйте дальнейшим инструкциям", component: (props) => <ResetContainer {...props} /> },
        { key: "Change", title: "Восстановление пароля", desc: "Для продолжения заполните данные", component: (props) => <ChangeContainer {...props} /> },
        { key: "Register", title: "Регистрация", desc: "Для регистрации введите данные:", component: (props) => <RegisterContainer {...props} /> },
    ];

    const typeHandler = useCallback((type) => {
        setType(type)
    },[]);

    return (
        <Page>
            <div className={styles.account}>
                <AnimatePresence>
                    { content.map(({ component, key, title, desc }, index) => (
                        <React.Fragment key={key}>
                            { key === type ? (
                                <motion.div
                                    exit="exit"
                                    key={key}
                                    animate="animate"
                                    initial="initial"
                                    variants={cardVariants}
                                    style={{ width: '100%'}}
                                >
                                    { component({ typeHandler }) }
                                </motion.div>
                            ) : null }
                        </React.Fragment>
                    )) }
                </AnimatePresence>
            </div>

        </Page>
    )
};

export default Account;
