import React, {useState} from "react";
import {useMutation} from "@apollo/client";
import { LOGIN_USER_BY_EMAIL} from "../../../../graphql";
import {useNavigate} from "react-router-dom";
import TextField from "../../../../ui/TextField/TextField";
import Button from "../../../../ui/Button/Button";
import AuthWithEmailSchema from "./AuthWithEmail.schema";
import {closeAuthModal} from "../../../../store/reducers/AuthSlice";
import {useDispatch} from "react-redux";
import Form from "../../../Form/Form";
import styles from "./AuthWithEmail.module.scss";


const AuthWithEmail = ({ typeHandler }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState();

    const [loginUser, { loading }] = useMutation(LOGIN_USER_BY_EMAIL, {
        update(_, { data: { userLoginByEmail: userData } } ) {
            localStorage.setItem('accessToken', userData.accessToken);
            dispatch(closeAuthModal());
            navigate('/cabinet');
        },
        onError(err) {
            setErrors(err.graphQLErrors[0].message);
        }
    });

    const onSubmit = async (data) =>
        await loginUser({ variables: { input: { ...data }}});


    return (
        <Form
            error={errors}
            onSubmit={onSubmit}
            validationSchema={AuthWithEmailSchema}
        >
            {({control}) => (
                <>
                    <TextField
                        name="email"
                        label="Ваш email"
                        control={control}
                        placeholder="Введите email"
                    />

                    <TextField
                        type="password"
                        name="password"
                        control={control}
                        label="Ваш пароль"
                        placeholder="Введите пароль"
                    />
                    <div className={styles.auth__buttons}>
                        <Button type="submit" disabled={loading}>Войти</Button>
                        <button onClick={() => typeHandler("Reset")} className={styles.auth__reset}>Забыли пароль?</button>
                    </div>

                    <button onClick={() => typeHandler("Register")} className={styles.auth__register}>Нет аккаунта? Зарегистрироваться</button>
                </>
            )}
        </Form>
    );
};

export default AuthWithEmail;
