import React from "react";
import { useController } from "react-hook-form";
import styles from "./OptionField.module.scss";
import checkbox from "../../assets/img/check.svg";

const OptionField = ({ name, control, options, loading }) => {
  const { field } = useController({ control, name });

  console.log(options);

  const changeHandler = (item) => {
    field.onChange(item.value)
  }

  return (
      <div className={styles.option}>
        { !loading && options && options.map(item => (
            <div onClick={() => changeHandler(item)} key={item.value}>
              <div className={styles.switcher}>
                <div className={styles.switcherBox}>
                  {field.value === item.value && <img src={checkbox} alt="" /> }
                </div>
                <div className={styles.switcherTitle}>
                  <p>{item.title}</p>
                  <span>{item.description}</span>
                </div>
              </div>
            </div>
        ))}
      </div>
  )
}

export default OptionField;
