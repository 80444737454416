import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useForm } from "react-hook-form";
import Stations from "../../../UI/Stations/Stations";
import { useMutation, useQuery } from '@apollo/client';
import classes from './SubscribeCreate.module.scss';
import loadingImage from "../../../../assets/img/loading.svg";
import OptionField from "../../../../ui/OptionField/OptionField";
import {EXPAND_SUBSCRIBE, FETCH_SERVICE} from "../../../../graphql";
import {closeSubscribeModal} from "../../../../store/reducers/SubscribeSlice";

const defaultValues = {
    service: "",
    stations: [],
    subscriptionID: ""
};

const SubscribeExpand = () => {
    const dispatch = useDispatch();
    const { expand: { subscriptionID } } = useSelector(state => state.subscribe);

    const { data: { getSubscriptionService: services } = {}, loading: loadingServices } = useQuery(FETCH_SERVICE, {
        fetchPolicy: "cache-and-network"
    });

    const { control, handleSubmit, watch } = useForm({
        mode: "onSubmit",
        defaultValues
    });

    const service = watch("service");

    const [expandSubscribe, { loading }] = useMutation(EXPAND_SUBSCRIBE, {
        update(proxy, { data: { setSubscriptionService: order } }) {
            window.open(order.payment_page_url, "_blank");
            dispatch(closeSubscribeModal());
        }
    });

    const onSubmit = handleSubmit(async data => {
        await expandSubscribe({ variables: { input: { ...data, subscriptionID }}})
    });

    console.log(services)
    return (
        <form encType="multipart/form-data" className={loading ? `${classes.form} ${classes.FormLoading}` : classes.form} onSubmit={onSubmit}>
            <div className={classes.form__loading}>
                <img src={loadingImage} alt=""/>
            </div>
            <div className={classes.form__title}>
                <h1>Расширение подписки</h1>
                <p>Для расширения подписки выберите нужный параметр:</p>
                <span className={classes.GeneralError}>{}</span>
            </div>
            <div className={classes.form__options}>
                <OptionField
                    name="service"
                    control={control}
                    loading={loadingServices}
                    options={services && services.map((item) => {
                        return {
                            value: item._id,
                            title: item.title,
                            description: `Стоимость услуги: ${item.price} тенге`
                        }
                    })}
                />

            </div>

            { service && service === "6410585e134aebd8a9cbf5e6" && (
                <Stations
                    name="stations"
                    stationCount={1}
                    control={control}
                    label="Выберите базу"
                    placeholder="Выберите базу"
                />
            ) }

            <div className={classes.FormButtons}>
                <button className={classes.FormSubmit}>Перейти к оплате</button>
            </div>
        </form>
    )
};

export default SubscribeExpand;

