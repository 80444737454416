import React from "react";
import {useDispatch} from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { CREATE_PAY_SUBSCRIBE } from "../../../../graphql";
import {changeSubscribeStep} from "../../../../store/reducers/SubscribeSlice";

const SubscribePay = ({ _id, children }) => {
    const dispatch = useDispatch()
    const [createPay, { loading }] = useLazyQuery(CREATE_PAY_SUBSCRIBE, {
        variables: { input: { _id } },
        onCompleted: ({ paySubscription }) => {
            window.open(paySubscription?.payment_page_url, '_blank')
            setTimeout(() => {
                dispatch(changeSubscribeStep({ step: "Finish" }))
            }, 2500);
        }
    });

    return (
        <>
            {children({ loading, createPay })}
        </>
    )
}

export default SubscribePay;
