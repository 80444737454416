import React from "react";
import Label from "../Label/Label";
import Group from "../Group/Group";
import Error from "../Error/Error";
import Select  from "../Select/Select";
import { useController } from "react-hook-form";

const SelectField = ({
   name,
   label,
   control,
   onChange = () => undefined,
   ...props
}) => {
  const { field, fieldState: { error } } = useController({ control, name });

  const handleChange = (option) => {
      onChange(option);
      field.onChange(option.value)
  }

  return (
    <Group>
      { label && <Label htmlFor={field.name}>{label}</Label> }
      <Select
        {...props}
        name={field.name}
        isError={!!error}
        defaultValue={field.value}
        onChange={(option) => handleChange(option)}
      />
      { error && <Error message={error.message} /> }
    </Group>
  )
}

export default SelectField;
