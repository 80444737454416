import * as Yup from "yup";

const RequisiteCreateSchema = Yup.object().shape({
  companyBin: Yup.string().required('Заполните поле'),
  companyName: Yup.string().required('Заполните поле'),
  bankName: Yup.string().required('Заполните поле'),
  bankRequisites: Yup.string().required('Заполните поле'),
  companyAddress: Yup.string().required('Заполните поле'),
});


export default RequisiteCreateSchema;
