import React from 'react';
import classes from "../Group/Group.module.scss";

const Group = ({ children }) => {
    return (
        <div className={classes.group}>
            { children }
        </div>
    )
};

export default Group;
