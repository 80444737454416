import React from 'react';
import classes from "./Button.module.scss";
import { NavLink } from "react-router-dom";
import useIsAuth from "../../../hooks/useIsAuth";

const DemoButton = () => {
    const { isAuth } = useIsAuth();


    if (!isAuth) {
        return (
            <button className={classes.DemoButton} onClick={() => undefined}>
                Демо на 3 дня
            </button>
        )
    }

    if (isAuth) {
        return (
            <>
                <NavLink className={classes.DemoButton} to="/cabinet#device">
                    Демо на 3 дня
                </NavLink>
            </>
        )
    }
}

export default DemoButton;
