import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    order: null,
    opened: false,
    method: "Email",
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        openAuthModal: (state) => {
            state.opened = true;
        },
        closeAuthModal: (state) => {
            state.opened = false;
        },
        changeAuthMethod: (state, action) => {
            state.method = action.payload.method;
        }
    },
})

export const { openAuthModal, closeAuthModal, changeAuthMethod } = authSlice.actions;
export default authSlice.reducer;
