import React from 'react';
import { Link } from "react-router-dom";
import classes from './Home.module.scss';
import map from '../../assets/img/map.svg';
import Maps from "../../components/Maps/Maps";
import Page from "../../components/Page/Page";
import StationRequest from "../../components/screens/station/StationRequest/StationRequest";

const Home = () => {
    return (
       <Page>
            <div className={classes.Home} >
                <div className={classes.Image}>
                    <img src={map} alt="" />
                </div>
                <div className={classes.Text}>
                    <h1>Сеть базовых станций <br />GeokursNET</h1>
                    <p>
                        На этой странице Вы можете подключиться к сети базовых станций нашей компании.
                        Мы рассматриваем этот проект <br/>как некоммерческий и плата берется только
                        за поддержание работоспособности станций.
                    </p>
                    <p>
                        Подключайтесь! Мы работаем для Вас!
                    </p>

                    <Link className={classes.Button} to="/cabinet">
                        Подключиться
                    </Link>
                </div>
            </div>

           <StationRequest />

            <Maps banner={false} form={true} />
        </Page>
    )
};

export default Home;
