import React, { useEffect, useRef } from 'react';

const BitrixForm = ({ className, children }) => {
  const scriptRef = useRef(null);

  useEffect(() => {
    // Создаем элемент скрипта
    const script = document.createElement('script');
    script.src = 'https://crm.geokurs.kz/upload/crm/form/loader_16_z2v87e.js';
    script.async = true;
    script.dataset.b24Form = 'click/16/z2v87e';
    script.dataset.skipMoving = 'true';

    // Добавляем скрипт в DOM
    scriptRef.current.appendChild(script);

    const button = document.createElement('button');
    button.innerText = children;
    button.className = className;

    scriptRef.current.appendChild(button);

    // Очистка перед удалением компонента
    return () => {
      if (scriptRef.current) {
        scriptRef.current.removeChild(script);
      }
    };
  }, []);

  console.log(window, scriptRef.current)

  return (
      <>
        <div ref={scriptRef}></div>
      </>
  );
};


export default BitrixForm;