import React from 'react';
import Portal from "../Portal/Portal"
import ModalOverlay from "./ModalOverlay";
import { useMount }  from "../../hooks/useMount";


const Modal = (props) => {
  const {
    onClose,
    children,
    isOpened,
    size = "default",
    hideCloseButton = false
  } = props;

  const { mounted } = useMount({ opened: isOpened })

  if (!mounted) return null;

  return (
    <Portal>
        <ModalOverlay onClose={hideCloseButton ? undefined : onClose} opened={isOpened} size={size}>
          { children({
            closeHandler: onClose
          }) }
        </ModalOverlay>
    </Portal>
  );
};

export default Modal;
