import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    order: null,
    opened: false,
    prolong: {
        opened: false,
        type: "",
        _id: ""
    },
    expand: {
        opened: false,
        subscriptionID: ""
    },
    step: "Create",
    payMethod: "Card"
}

const subscribeSlice = createSlice({
    name: 'subscribe',
    initialState,
    reducers: {
        setSubscribe: (state, action) => {
          state.order = action.payload.order;
        },
        openSubscribeModal: (state, action) => {
            state.opened = true;
            state.step = action.payload ?? "Create";
        },
        closeSubscribeModal: (state) => {
            state.opened = false;
        },
        changeSubscribeStep: (state, action) => {
            state.step = action.payload.step;
        },
        changePayMethod: (state, action) => {
            state.payMethod = action.payload.payMethod;
        },
        setFinish: (state) => {
            state.step = "Create";
            state.payMethod = "Card";
        },
        openSubscribeProlongModal: (state, action) => {
            state.prolong.opened = true;
            state.prolong._id = action.payload._id;
            state.prolong.type = action.payload.type;
        },
        closeSubscribeProlongModal: (state) => {
            state.prolong.opened = false;
            state.prolong.type = "";
            state.prolong._id = "";
        },
        openSubscribeExpandModal: (state, action) => {
            state.expand.opened = true;
            state.expand.subscriptionID = action.payload;
        },
        closeSubscribeExpandModal: (state) => {
            state.expand.opened = false;
            state.expand.subscriptionID = "";
        },
    },
})

export const { setSubscribe, setFinish, closeSubscribeModal, openSubscribeModal, changeSubscribeStep, changePayMethod, openSubscribeProlongModal, closeSubscribeProlongModal, openSubscribeExpandModal, closeSubscribeExpandModal } = subscribeSlice.actions;
export default subscribeSlice.reducer;
