import React from 'react';
import Form from "../../../Form/Form";
import { useMutation } from "@apollo/client";
import styles from "./StationForm.module.scss";
import Button from "../../../../ui/Button/Button";
import TextField from "../../../../ui/TextField/TextField";
import { CREATE_REQUEST_STATION } from "../../../../graphql";
import { PHONE_MASK } from "../../auth/AuthWithPhone/AuthPhoneForm";
import CheckboxField from "../../../../ui/CheckboxField/CheckboxField";
import AuthWithEmailSchema from "../../auth/AuthWithEmail/AuthWithEmail.schema";


const StationForm = ({ stepHandler }) => {
    const [requestStation, { loading }] = useMutation(CREATE_REQUEST_STATION, {
        update(_, { data } ) {
            stepHandler("Success")
        }
    });

    const onSubmit = async (data) =>
        await requestStation({ variables: { input: { ...data }}})

    return (
        <div className={styles.request__container}>
            <div className={styles.request__title}>
                <h1>Заявка на установку базы</h1>
                <p>Заполните форму ниже:</p>
            </div>
            <Form
                onSubmit={onSubmit}
                validationSchema={AuthWithEmailSchema}
            >
                {({ control }) => (
                    <>
                        <TextField
                            name="city"
                            label="Населенный пункт"
                            control={control}
                            placeholder="Куда поставить базу?"
                        />

                        <TextField
                            name="fio"
                            label="Фио"
                            control={control}
                            placeholder="Введите ФИО"
                        />

                        <TextField
                            name="fio"
                            label="Фио"
                            control={control}
                            placeholder="Введите ФИО"
                        />

                        <TextField
                            name="phone"
                            control={control}
                            mask={PHONE_MASK}
                            label="Номер телефона"
                            placeholder="Введите номер телефона"
                        />

                        <CheckboxField
                            control={control}
                            name="confirm"
                            options={[
                                {
                                    value: true,
                                    label: () => ( <>Имеется место для установки?</>)
                                },
                            ]}
                        />

                        <div className={styles.request__buttons}>
                            <Button type="submit" disabled={loading}>Отправить заявку</Button>
                        </div>
                    </>
                )}
            </Form>
        </div>
    );
};

export default StationForm;
