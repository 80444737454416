import React from "react";
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import 'normalize.css';
import './App.css';
import {createUploadLink} from "apollo-upload-client";
import {setContext} from "apollo-link-context";
import { Provider } from "react-redux";
import { setupStore } from "./store";
import { BrowserRouter } from "react-router-dom";
import {ApolloClient, InMemoryCache, ApolloProvider} from "@apollo/client";
import App from "./App";

const httpLink = createUploadLink({
    credentials: 'include',
    headers: { 'Apollo-Require-Preflight': 'true' },
    uri: process.env.NODE_ENV === 'production' ? 'https://api3.geokurs.kz/graphql' : 'http://localhost:6001/graphql'
});


const authLink = setContext(async (_, { headers }) => {
    const token = localStorage.getItem('accessToken');

    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : ''
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    credentials: 'include',
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        },
    },
});



const store = setupStore()



ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
