import React from 'react';
import classes from "./Table.module.scss";
import classnames from 'classnames';

export const Table = (props) => {
    return (
        <div className={classes.Wrapper}>
            <table className={classes.Table}>
                <Thead>
                    { props.value.map((item, index) => {

                        let cls = classnames({
                            [classes.Brand]: item.type === 'brand'
                        });

                        let key = item.name + Math.random();

                        return (
                            <Th key={key} className={cls}>{item.label}</Th>
                        )
                    }) }
                </Thead>
                <Tbody>
                    { props.children }
                </Tbody>
            </table>
        </div>
    )
};


export const Thead = (props) => {
    return (
        <thead className={classes.Thead}>
            <tr>
                { props.children }
            </tr>
        </thead>
    )
}


export const Tbody = (props) => {
    return (
        <tbody className={classes.Tbody}>
            {props.children}
        </tbody>
    )
}

export const Tr = (props) => {
    return (
        <tr style={{ ...props.style }}>
            { props.children }
        </tr>
    )
}

export const Td = (props) => {
    return (
        <td>
            { props.children }
        </td>
    )
}

export const Th = (props) => {
    return (
        <th className={props.className}>
            { props.children }
        </th>
    )
}

