import React from 'react';
import { useQuery } from "@apollo/client";
import { IIN_EXIST } from "../../../../graphql";
import styles from "./SubscribeCard.module.scss";
import SubscribeIIN from "../SubscribeIIN/SubscribeIIN";
import SubscribeOrder from "../SubscribeOrder/SubscribeOrder";

const SubscribeCard = () => {
    const { data: { userIinExist: user } = {} } = useQuery(IIN_EXIST, {
        fetchPolicy: "cache-and-network"
    });

    return (
        <div className={styles.card}>
            { user && user?.iin ? (
                <SubscribeOrder />
            ) : (
                <SubscribeIIN />
            ) }
        </div>
    )
};

export default SubscribeCard;
