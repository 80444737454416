import React from 'react';
import Wrapper from "../../../Wrapper/Wrapper";
import styles from "./ResetSuccess.module.scss";
import confirm from "../../../../assets/img/mailconfirm.svg";

const ResetSuccess = () => {
    return (
        <Wrapper>
            <div className={styles.reset}>
                <img src={confirm} alt=""/>
                <h1 className="dark">Пароль
                    успешно сброшен</h1>
                <p className="centered">
                    Данные для авторизации были высланы
                    на адрес электронной почты указанный
                    при регистрации.
                </p>
            </div>
        </Wrapper>
    );
};

export default ResetSuccess;
