import React from 'react';
import Wrapper from "../../../Wrapper/Wrapper";
import styles from "./StationSuccess.module.scss";
import confirm from "../../../../assets/img/mailconfirm.svg";

const StationSuccess = () => {
    return (
        <Wrapper>
            <div className={styles.reset}>
                <img src={confirm} alt=""/>
                <h1 className="dark">Заявка успешно отправлена</h1>
                <p className="centered">
                    В ближайшее время наши специалисты рассмотрят вашу заявку.
                </p>
            </div>
        </Wrapper>
    );
};

export default StationSuccess;
