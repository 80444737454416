import React from 'react';
import Wrapper from "../../../Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import styles from "./ChangeSuccess.module.scss";
import confirm from "../../../../assets/img/mailconfirm.svg";

const ChangeSuccess = () => {
    const navigation = useNavigate();
    return (
        <Wrapper>
            <div className={styles.change}>
                <img src={confirm} alt=""/>
                <h1 className="dark">Пароль успешно сброшен</h1>
                <button onClick={() => navigation("/cabinet")} className={[styles.button, styles.button_theme_default].join(' ')}>Войти в кабинет</button>
            </div>
        </Wrapper>
    );
};

export default ChangeSuccess;
