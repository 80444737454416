import {clsx} from "clsx";
import { useController } from "react-hook-form";
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from "./FileField.module.scss";
import Error from "../Error/Error";
import Group from "../Group/Group";
import Label from "../Label/Label";

const FileField = ({
    name,
    control,
    label,
    acceptFiles,
}) => {
    const [files, setFiles] = useState([]);
    const [status, setStatus] = useState('initial');
    const { field, fieldState: { error } } = useController({ control, name });

    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles);
        setFiles(acceptedFiles);
        setStatus('selected')
        field.onChange(acceptedFiles[0]);
    }, [field])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop, maxFiles: 1, multiple: false, maxSize: 50000000, accept: acceptFiles
    });

    const uploaderStyle = clsx(styles.file__uploader, {
        [styles.file__uploader_state_error]: !!error
    });

    console.log(files[0])

    return (
        <Group>
            { label && <Label htmlFor={field.name}>{label}</Label> }
            <div className={styles.file}>
                <div {...getRootProps()} className={uploaderStyle}>
                    { status === 'initial' && (
                        <div className={styles.file__initial}>
                            Выберите/загрузите файл
                        </div>
                    ) }
                    { status === 'selected' && (
                        <div className={styles.file__list}>
                            { files?.map(item => (
                                <span key={item.name}>{item.name}</span>
                            )) }
                        </div>
                    ) }
                    <input {...getInputProps()} />
                </div>
                { error && <Error message={error.message} /> }
            </div>
        </Group>
    )
};

export default FileField;
