import React from 'react';
import classes from './Maps.module.scss';
import Banner from "../Banner/Banner";
import arrow from '../../assets/img/arrow.svg';
import pinOnline from '../../assets/img/pin.svg';
import pinOffline from '../../assets/img/pinoffline.svg';
import { useQuery } from "@apollo/client";
import { FETCH_MAP_STATIONS } from "../../graphql";
import { Map, Placemark, Circle, YMaps } from "react-yandex-maps";
import {useDispatch} from "react-redux";
import {openRequestModal} from "../../store/reducers/RequestSlice";

const mapData = {
    center: [48.207479, 67.062836],
    zoom: 4.5,
};


const Maps = ({ banner, form }) => {
    const dispatch = useDispatch();
    const { loading, data: { getStations: stations } = {} } = useQuery(FETCH_MAP_STATIONS, {
        variables: { filter: { search: "" } },
    });

    const cls = (banner) => {
        if (banner) {
            return classes.Map;
        } else {
            return classes.Map + ' ' + classes.MapBanner;
        }
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Title}>
                <h2>Зоны покрытия</h2>
                <span className={classes.Online}>Работает</span>
                <span className={classes.Offline}>Временно отключена</span>
            </div>
            <div className={classes.Container}>
                <div className={cls()}>
                    { loading ? (
                        <h1>Загрузка...</h1>
                    ) : (
                        <YMaps version="2.1.77">
                            <Map defaultState={mapData}
                                 modules={["templateLayoutFactory", "layout.ImageWithContent"]}
                                 width="100%" height="500px">
                                {stations && stations.map((station, index) => {
                                    let cord = [station.cords.n, station.cords.e];
                                    return (
                                        <React.Fragment key={index}>
                                            <Placemark geometry={cord} options={{
                                                iconLayout: 'default#image',
                                                iconImageHref: station.status ? pinOnline : pinOffline,
                                                iconImageSize: [28, 37],
                                                iconImageOffset: [-14, -37],
                                            }}/>
                                            <Circle
                                                geometry={[cord, 20000]}
                                                options={{
                                                    draggable: false,
                                                    fillColor: station.status ? "#227831" : "#BE4806",
                                                    strokeColor: "#ffffff",
                                                    fillOpacity: 0.35,
                                                    strokeOpacity: 0.8,
                                                    strokeWidth: 1
                                                }}
                                            />
                                            <Circle
                                                geometry={[cord, 100000]}
                                                options={{
                                                    draggable: false,
                                                    fillColor: station.status ? "#227831" : "#BE4806",
                                                    strokeColor: station.status ? "#227831" : "#BE4806",
                                                    fillOpacity: 0.35,
                                                    strokeOpacity: 1,
                                                    strokeWidth: 1
                                                }}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </Map>
                        </YMaps>
                    )}
                </div>
                { banner && <Banner /> }
            </div>

            { form &&
                <div className={classes.Form}>
                    <div>
                        <h2>В вашем городе нет базовой станции?</h2>
                        <p>Оставить заявку на ее установку</p>
                    </div>
                    <div>
                        <button className={classes.Button} onClick={() => dispatch(openRequestModal())}>Оставить заявку на
                            установку базы<img src={arrow} alt=""/>
                        </button>
                    </div>
                </div>
            }
        </div>
    )
};


export default Maps;
