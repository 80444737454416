import styles from "./Checkbox.module.scss";
import React, { forwardRef } from "react";
import checkbox from "../../assets/img/check.svg";


const Checkbox = forwardRef(({ name, value, label, onChange }, ref) => {
  return (
    <label className={styles.checkbox}>
      <input
        ref={ref}
        id={value}
        name={name}
        value={value}
        type="checkbox"
        onChange={onChange}
        className={styles.checkbox__input}
      />
      <span className={styles.checkbox__checkbox}>
         <span className={styles.checkbox__tick}><img src={checkbox} alt=""/></span>
      </span>
      <span className={styles.checkbox__label}>{label}</span>
    </label>
  )
});

Checkbox.displayName = "Checkbox";

export default Checkbox;
