import React from 'react';
import styles from "./Wrapper.module.scss";

const Wrapper = ({ children, title, subscription }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapper__container}>
                { (title || subscription) && (
                    <div className={styles.wrapper__title}>
                        <h1>{title}</h1>
                        <p>{subscription}</p>
                    </div>
                ) }
                <div className={styles.wrapper__form}>
                    { children }
                </div>
            </div>
        </div>
    );
};

export default Wrapper;
