import * as Yup from "yup";

const ChangeSchema = Yup.object().shape({
    password: Yup.string()
        .min(5, "Минимальная длинна пароля 5 символов")
        .max(20, "Максимальная длина пароля 20 символов")
        .required("Данное поле обязательно для заполнения"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
        .min(5, "Минимальная длинна пароля 5 символов")
        .max(20, "Максимальная длина пароля 20 символов")
        .required("Данное поле обязательно для заполнения"),
});

export default ChangeSchema;
