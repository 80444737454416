import React from 'react';
import Moment from "react-moment";
import classes from "../../Tabs/Tab/Tab.module.scss";
import calendar from '../../../assets/img/calendar.svg';
import { daysCount, daysLeft } from "../../../util/helpers";


export const DateStart = ({date: { disabledAt, activatedAt }}) => {
    if (activatedAt) {
        return (
            <>
                <h3><Moment format="DD.MM.YYYY">{activatedAt}</Moment></h3>
                <p className={classes.Green}>{daysCount(disabledAt, activatedAt)}</p>
            </>
        )
    } else {
        return (
            <>
                <i className={classes.Orange} />
                <p className={classes.Orange}>Проверяем данные...</p>
            </>
        )
    }
};



export const DateEnd = ({ date: { disabledAt, activatedAt }, plan }) => {
    if (activatedAt) {
        return (
            <>
                <h3><Moment format="DD.MM.YYYY">{disabledAt}</Moment></h3>
                <p className={classes.Green}>{daysLeft(disabledAt, activatedAt)}</p>
            </>
        )
    } else {
        return (
            <>
                <i className={classes.Orange} />
                <p className={classes.Orange}>Проверяем данные...</p>
            </>
        )
    }
};


export const DatePay = ({ date: { disabledAt, paidAt } }) => {
    if (paidAt) {
        return (
            <>
                <h3><Moment format="DD.MM.YYYY">{paidAt}</Moment></h3>
                <p className={classes.Green}>{daysCount(disabledAt, paidAt)}</p>
            </>
        )
    } else {
        return (
            <>
                <i className={classes.Orange} />
                <p className={classes.Orange}>Ожидаем оплату</p>
            </>
        )
    }
};

export const DateButton = ({ value, onClick, placeholder  }) => {
    return (
        <div className={classes.DateButtonWrapper}>
            <img src={calendar} alt="" />
            <input type="text" className={classes.DateButton} value={value} onClick={onClick} placeholder={placeholder}/>
        </div>
    )
}